import React, {useEffect, useState} from 'react';
import {getCityToShow, hasAValue} from "../../../uitls/SharedFunctions";
import {Alert} from "react-bootstrap";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import {useSelector} from "react-redux";



export default function UserDetailsProfileSection({user}) {
    const citiesRS = useSelector(state => state.citiesRS);



    const initString = {
        header: stringsTranslate(appWords.profiel_details_1),
        subTitle: stringsTranslate(appWords.student_information),
        email_label: stringsTranslate(appWords.profile_email_label),
        student_email: stringsTranslate(appWords.profile_student_email_label),
        gender: stringsTranslate(appWords.gender),
        phoneNumber: stringsTranslate(appWords.phone_number),
        profiel_details: stringsTranslate(appWords.profiel_details),
        bank_account: stringsTranslate(appWords.bank_account),
        birthday: stringsTranslate(appWords.birthday),
        postal_code_label: stringsTranslate(appWords.postal_code_label),
        house_number_label: stringsTranslate(appWords.house_number_label),
        student_city: stringsTranslate(appWords.student_city),
        no_data: stringsTranslate(appWords.no_data),
        student_information: stringsTranslate(appWords.profiel_option),
        for_changing_profile_1: stringsTranslate(appWords.for_changing_profile_1),
        for_changing_profile_2: stringsTranslate(appWords.for_changing_profile_2),
        for_changing_profile_3: stringsTranslate(appWords.for_changing_profile_3)

    }
    return (
        <div className={"mt-4 ml-4"}>
            <h1>{initString.student_information}</h1>
            <div className={"divider-horizontal"}/>
            <div className={"col-lg-9 col-sm-12 col-sm-12 d-flex justify-content-start mb-5 ml-0 pl-0"}>

                <div className={"card card-body radius-15 border-0 w-100 in-store-saved-discount-card-body  mt-3"}>
                    <div className="row">

                        <div className={"col-12 justify-content-center align-items-center"}>
                            <a>
                                {initString.for_changing_profile_1}
                                <a className="font-weight-bolder text-primary h6 px-1" href={"/download-app"}>
                                    {initString.for_changing_profile_2}
                                </a>
                                {initString.for_changing_profile_3}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-12  mt-5 d-flex "}>
                    {hasAValue(user) && <div className={"text-left"}>

                        <ul className={"row"}>
                            <li className={"col-lg-6 col-md-6 col-sm-10 mb-3"}>
                                <h4>{initString.email_label}</h4>
                                <p>{hasAValue(user.email) ? user.email : initString.no_data}</p>
                            </li>
                            <li className={"col-lg-6 col-md-6 col-sm-10 mb-3"}>
                                <h4>{initString.student_email} </h4>
                                <p>{hasAValue(user.studentMail) ? user.studentMail : initString.no_data}</p>
                            </li>
                            <li className={"col-lg-6 col-md-6 col-sm-10 mb-3"}>
                                <h4>{initString.phoneNumber} </h4>
                                <p>{hasAValue(user.countryCode) ? user.countryCode : ''} {hasAValue(user.phoneNumber) ? user.phoneNumber : initString.no_data}</p>
                            </li>
                            <li className={"col-lg-6 col-md-6 col-sm-10 mb-3"}>
                                <h4>{initString.gender} </h4>
                                <p>{hasAValue(user.gender) ? stringsTranslate(user.gender) : initString.no_data}</p>
                            </li>
                            <li className={"col-lg-6 col-md-6 col-sm-10 mb-3"}>
                                <h4>{initString.birthday}</h4>
                                <p>{hasAValue(user.dateOfBirth) ?  new Date(Date.parse(user.dateOfBirth)).toLocaleDateString("nl") : initString.no_data}</p>
                            </li>
                            <li className={"col-lg-6 col-md-6 col-sm-10 mb-3"}>
                                <h4>{initString.bank_account}</h4>
                                <p>{hasAValue(user?.paymentAccountDetails?.consumerAccount) ? user?.paymentAccountDetails?.consumerAccount : initString.no_data}</p>
                            </li>

                            <li className={"col-lg-6 col-md-6 col-sm-10 mb-3"}>
                                <h4>{initString.student_city}</h4>
                                <p>{hasAValue(user.cityId) ? getCityToShow(user.cityId, citiesRS).name : initString.no_data}</p>
                            </li>
                        </ul>
                    </div>}
                </div>
            </div>
        </div>
    );


}
