import ReactPixel from 'react-facebook-pixel';

export const facebookTrackPurchase = () =>{
    ReactPixel.track('Purchase', {
        value: 12.50,
        currency: 'EUR'
    });
}

export const facebookTrackAddToCart = () =>{
    ReactPixel.track('AddToCart');
}

export const facebookTrackInitiateCheckout = () =>{
    ReactPixel.track('InitiateCheckout');
}

export const facebookTrackCompleteRegistration = () =>{
    ReactPixel.track('CompleteRegistration');
}

