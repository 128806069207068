import React, {useEffect, useState} from 'react';
import ImageComponent from "./ImageComponent";
import {useSelector} from "react-redux";

export default function SnipTechOnlineDiscountCard({item, hideFavorite = false}) {
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const [itemShortDesc, setItemShortDesc] = useState(item[appLanguageRS]?.shortDes)

    useEffect(() => {
        setItemShortDesc(item[appLanguageRS]?.shortDes);
    }, [appLanguageRS])

    return (
        <div className={"card radius-15 border-0 w-100 store-discount-card cursor-pointer shadow"}
             onClick={() => goToInfo()}>
            <ImageComponent src={item.background} className="homepage-online-section-online-card-background" alt=""/>
            <div className="card-body row store-discount-card-body">
                <div className={"col-12 d-flex justify-content-center"}>
                    <div
                        className="bg-white radius-15 online-discount-card-logo shadow d-flex justify-content-center align-items-center">
                        <ImageComponent src={item.logo}
                                        alt="" className={"online-discount-card-logo-img"}/>
                    </div>


                </div>
                <div className={"col-12 text-center"}>
                    <h4 className="w-100 mt-2 text-knaek-title">{(item?.title?.toString()?.length < 25) ? item.title : item?.title?.toString()?.substring(0, 25) + " ...."}</h4>
                    <h6 className="w-100 text-knaek-regular">{(itemShortDesc?.toString()?.length < 40) ? itemShortDesc : itemShortDesc?.toString()?.substring(0, 40) + " ...."}</h6>
                </div>
            </div>

        </div>
    );


    function hyphenateAndEncode(str) {
        // Replace spaces and forward slashes with hyphens, then URI encode the result
        return encodeURIComponent(str.replace(/\s+|\//g, '-'));
    }

    function goToInfo() {
        let targetTitle = item.title;
        let targetId = item.id;

        window.location.href = `/cashback/studentenkorting/${hyphenateAndEncode(targetTitle)}/${encodeURIComponent(targetId)}`;
    }
}
