import React, {useState} from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {IoMdCloseCircle} from "react-icons/io";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

export default function SnipTechFilterCategoryOnlineDiscounts({filters, setFilters}) {

    let intiString = {
        toysHobby : stringsTranslate(appWords.toysHobby),
        imageSound: stringsTranslate(appWords.imageSound),
        sportsLeisure: stringsTranslate(appWords.sportsLeisure),
        computer: stringsTranslate(appWords.computer),
        photoVideo: stringsTranslate(appWords.photoVideo),
        gamesMoviesMusic: stringsTranslate(appWords.gamesMoviesMusic),
        telephoneInternet: stringsTranslate(appWords.telephoneInternet),
        eatDrink: stringsTranslate(appWords.eatDrink),
        beautifulHealthy: stringsTranslate(appWords.beautifulHealthy),
        fashion: stringsTranslate(appWords.fashion),
        holidayTravel: stringsTranslate(appWords.holidayTravel),
        houseGarden: stringsTranslate(appWords.houseGarden),
        booksMagazines: stringsTranslate(appWords.booksMagazines),
        giftsGadgets: stringsTranslate(appWords.giftsGadgets),
        aDayOut: stringsTranslate(appWords.aDayOut),
        financial: stringsTranslate(appWords.financial),
        householdAppliances: stringsTranslate(appWords.householdAppliances),
        departmentStore: stringsTranslate(appWords.departmentStore),
        commercial: stringsTranslate(appWords.commercial),

        sport_and_free_time: stringsTranslate(appWords.sport_and_free_time),
        popular_online_discounts: stringsTranslate(appWords.popular_online_discounts),
        computer_and_internet: stringsTranslate(appWords.computer_and_internet),
        food_and_drinks: stringsTranslate(appWords.food_and_drinks),
        clothes_and_accessories: stringsTranslate(appWords.clothes_and_accessories),
        others: stringsTranslate(appWords.others),
        shopping: stringsTranslate(appWords.shopping),
        categories: stringsTranslate(appWords.categories),
        categories_list: stringsTranslate(appWords.categories_list)
    }

    // Helper function to check if the category is selected
    const isCategorySelected = (item) => {
        return filters?.category && filters.category === item.ids[0];
    };

    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const filterItems = [
        {
            title: intiString.toysHobby,
            ids: [8498],
        },
        {
            title: intiString.imageSound,
            ids: [1447],
        },
        {
            title: intiString.sportsLeisure,
            ids: [1457],
        },
        {
            title: intiString.computer,
            ids: [1528],
        },
        {
            title: intiString.photoVideo,
            ids: [1564],
        },
        {
            title: intiString.gamesMoviesMusic,
            ids: [1547],
        },
        {
            title: intiString.telephoneInternet,
            ids: [1514],
        },
        {
            title: intiString.eatDrink,
            ids: [6272],
        },
        {
            title: intiString.beautifulHealthy,
            ids: [1465],
        },
        {
            title: intiString.fashion,
            ids: [1415],
        },
        {
            title: intiString.holidayTravel,
            ids: [1432],
        },
        {
            title: intiString.houseGarden,
            ids: [1475],
        },
        {
            title: intiString.booksMagazines,
            ids: [1573],
        },
        {
            title: intiString.giftsGadgets,
            ids: [1555],
        },
        {
            title: intiString.aDayOut,
            ids: [1596],
        },
        {
            title: intiString.financial,
            ids: [1501],
        },
        {
            title: intiString.householdAppliances,
            ids: [1489],
        },
        {
            title: intiString.departmentStore,
            ids: [1403],
        },
        {
            title: intiString.commercial,
            ids: [1607],
        },

        // {
        //     title: intiString.sport_and_free_time,
        //     ids: [8498, 7843, 1432, 1596, 1547, 1457],
        // },
        // {
        //     title: intiString.computer_and_internet,
        //     ids: [1607, 1514, 1528, 1447],
        // },
        // {
        //     title: intiString.food_and_drinks,
        //     ids: [6272, 1465],
        // },
        // {
        //     title: intiString.clothes_and_accessories,
        //     ids: [1403, 7843, 865, 1415, 1425],
        // },
        {
            title: intiString.others,
            ids: [865, 324, 104, 7843,1425],
        },

    ];

    return (
        <>
            {filters?.category && <div className="d-flex py-2 my-2 align-items-center filter-badge-text">
                    <a className="badge badge-pill filter-badge-body p-2">
                        <IoMdCloseCircle className="mr-2" onClick={() => {
                            setFilters({});
                        }}/>
                        {filters?.category.title}
                    </a>
                </div>}
            {filters?.webWeightLT && <div className="d-flex py-2 my-2 align-items-center filter-badge-text">
                <a className="badge badge-pill filter-badge-body p-2">
                    <IoMdCloseCircle className="mr-2" onClick={() => {
                        setFilters({});
                    }}/>
                    {intiString.popular_online_discounts}
                </a>
            </div>}

            <h4 className="my-3"></h4>

            <div className="W-100">
                <div
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={toggleDropdown}
                >
                    <h4 className="filter-city-accordion-item-header">{intiString.categories}</h4>
                    {isDropdownOpen ? (
                        <MdKeyboardArrowUp className="text-primary h5"/>
                    ) : (
                        <MdKeyboardArrowDown className="text-primary h5"/>
                    )}
                </div>

                {/* This div will show/hide based on the dropdownOpen state */}
                {isDropdownOpen && (
                    <div className="filter-city-accordion-item-body-md active">
                        <label className="cursor-pointer">
                            <input
                                className={"pr-5 mr-1 text-knaek-title check-box-city rounded-circle cursor-pointer "}
                                type="radio"
                                checked={filters?.webWeightLT !== undefined}
                                onClick={() => {
                                    setFilters(filters.webWeightLT > 0 ? {} : {webWeightLT: 70});
                                }}
                            /> {intiString.popular_online_discounts}
                        </label>
                        {filterItems.map((item, i) => {
                            let checked = filters?.category ? item?.ids?.toString() === (filters?.category.ids.toString()) : false;
                            return (
                                <label className="w-100 my-2 cursor-pointer" key={i}>
                                    <input
                                        className="pr-5 mr-1 text-knaek-title check-box-city rounded-circle cursor-pointer"
                                        type="radio"
                                        checked={checked}
                                        onClick={() => {
                                            setFilters(checked ? {} : {category: item});
                                        }}
                                    /> {item.title}
                                </label>
                            );
                        })}
                    </div>
                )}
            </div>

        </>
    );
}
