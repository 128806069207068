import React, {useEffect, useState} from 'react';
import {fetchMyTransactionSnipTechCall, fetchSnipTechBalancesCall} from "../../../api/ApiCalls";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appColors, appWords} from "../../../config/Constants";
import ClaimCashbackComponent from "../../AppComponents/ClaimCashbackComponent";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import PayoutModalComponent from "../../AppComponents/PayoutModalComponent";
import {failedAlertFunction} from "../../../uitls/AlertFunctions";
import {useSelector} from "react-redux";


export default function SavedOnlineDiscountsProfileSection() {
    const initStrings = {
        online_saved: stringsTranslate(appWords.online_saved),
        you_have_in_total_1: stringsTranslate(appWords.you_have_in_total_1),
        you_have_in_total_2: stringsTranslate(appWords.you_have_in_total_2),
        you_have_in_total_3: stringsTranslate(appWords.you_have_in_total_3),
        Where_is_my_transaction: stringsTranslate(appWords.Where_is_my_transaction),
        knaek_approved: stringsTranslate(appWords.knaek_approved),
        knaek_pending: stringsTranslate(appWords.knaek_pending),
        knaek_declined: stringsTranslate(appWords.knaek_declined),
        claimCashback: (stringsTranslate(appWords.claimCashback)).toString().toUpperCase(),
        payout: (stringsTranslate(appWords.payout)).toString().toUpperCase(),
        noBalanceForPayout: stringsTranslate(appWords.noBalanceForPayout),

        it_may_take_48_hours_before_your_cashback_is_visible_here: stringsTranslate(appWords.it_may_take_48_hours_before_your_cashback_is_visible_here),
    }

    const [showClaimModal, setShowClaimModal] = useState(false);
    const [sniptechTransactions, setSniptechTransactions] = useState([]);
    const [sniptechBalance, setSniptechBalance] = useState({})
    const [sniptechHoverIndex, setSniptechHoverIndex] = useState(null);
    const [showPayoutModal, setShowPayoutModal] = useState(false);

    useEffect(() => {
        window.location.href.toString().includes("openClaim") && setShowClaimModal(true)
        fetchMySniptechTransactionOnline()
    }, [])


    const appLanguageRS = useSelector(state => state.appLanguageRS);

    const formatNumber = (number) => {
        let contentLanguage = appLanguageRS === "en"? "en": "nl"
        return new Intl.NumberFormat(contentLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
    };

    return (
        <div className={"mt-4 ml-4"}>
            <h1>{initStrings.online_saved}</h1>
            <div className={"divider-horizontal"}/>
            <div className="row">
                <div className={"col-lg-8 col-sm-8 d-flex justify-content-start flex-wrap"}>
                    <div className={"card card-body radius-15 border-0 w-100 in-store-saved-discount-card-body"}>
                        <div className="row">
                            <div className={"col-12 justify-content-center align-items-center"}>
                                <a>
                                    {initStrings.you_have_in_total_1}
                                    <span className="font-weight-bolder text-primary h4">
                                        €{formatNumber(sniptechBalance?.savedAmount)}
                                    </span>
                                    {initStrings.you_have_in_total_2}
                                    <span className="font-weight-bolder text-primary h4">
                                        €{formatNumber(sniptechBalance?.payableAmount)}
                                     </span>
                                    {initStrings.you_have_in_total_3}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-4 d-flex justify-content-center align-items-center mt-sm-0 mt-3">
                    <KnaekPrimaryButton
                        title={initStrings.payout}
                        className={" mt-2"}
                        onClick={checkPayout}
                    />
                </div>
            </div>

            <div className="col-sm-12 col-lg-8 mb-3 d-flex justify-content-center claim-model-trigger">
                <span className={"text-primary cursor-pointer"}
                      onClick={() => setShowClaimModal(true)}>{initStrings.Where_is_my_transaction}</span>
            </div>
            <div className="col-sm-12 col-lg-8 mb-3 mt-5 d-flex justify-content-center">
                <span className={"text-primary"}>{initStrings.it_may_take_48_hours_before_your_cashback_is_visible_here}</span>
            </div>

            <div className={"col-sm-12 col-lg-8 mt-5 mb-3"}>
                <div className={"row"}>
                    {sniptechTransactions.map((item, i) => {
                        return (
                            <tr
                                key={i}
                                className="col-12 d-flex flex-wrap justify-content-between border-bottom py-2"
                                style={{
                                    backgroundColor: sniptechHoverIndex === i
                                        ? (item?.status?.toString().toLowerCase().includes("confirmed")
                                            ? appColors.knaek_valid
                                            : item?.status?.toString().toLowerCase().includes("rejected")
                                                ? appColors.knaek_danger
                                                : appColors.knaek_alert)
                                        : "",
                                    boxShadow: sniptechHoverIndex === i ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
                                    borderRadius: sniptechHoverIndex === i ? '15px' : '0px'

                                }}
                                onMouseEnter={() => setSniptechHoverIndex(i)}
                                onMouseLeave={() => setSniptechHoverIndex(null)}
                            >
                                <div className="row w-100">
                                    <section className="col-6 col-md-3 col-lg-3">
                                        {sniptechHoverIndex === i ?
                                            <span style={{color: 'white'}}>
                                                {item?.status?.toString().toLowerCase().includes("confirmed")
                                                    ? initStrings.knaek_approved
                                                    : item?.status?.toString().toLowerCase().includes("rejected")
                                                        ? initStrings.knaek_declined
                                                        : initStrings.knaek_pending}
                                            </span>
                                            : <svg width="20" height="20" viewBox="0 0 32 32">
                                                <path
                                                    fill={item?.status?.toString().toLowerCase().includes("confirmed")
                                                        ? appColors.knaek_valid
                                                        : item?.status?.toString().toLowerCase().includes("rejected")
                                                            ? appColors.knaek_danger
                                                            : appColors.knaek_alert
                                                    }
                                                    d="M28,2H10.67a4,4,0,0,0-4,4v6.67H4a4,4,0,0,0-4,4V26a4,4,0,0,0,4,4H21.33a4,4,0,0,0,4-4V19.33H28a4,4,0,0,0,4-4V6A4,4,0,0,0,28,2ZM22.67,18v8a1.33,1.33,0,0,1-1.34,1.33H4A1.32,1.32,0,0,1,2.67,26V16.67A1.33,1.33,0,0,1,4,15.33H21.33a1.34,1.34,0,0,1,1.34,1.34Zm6.66-2.67A1.33,1.33,0,0,1,28,16.67H25.33a4,4,0,0,0-4-4h-12V9.33h20Zm0-8.66h-20V6a1.33,1.33,0,0,1,1.34-1.33H28A1.32,1.32,0,0,1,29.33,6Zm-20.66,14V22a1.33,1.33,0,0,1-1.34,1.33H6A1.32,1.32,0,0,1,4.67,22V20.67A1.33,1.33,0,0,1,6,19.33H7.33A1.34,1.34,0,0,1,8.67,20.67Z"
                                                />
                                            </svg>
                                        }
                                    </section>

                                    <h5 className="text-left col-6 col-md-3 col-lg-3">{item?.converted_at?.toString().substring(0, 10)}</h5>
                                    <h5 className="text-left col-6 col-md-3 col-lg-3">{item?.description?.toString()}</h5>
                                    <p className="text-left text-lg-right text-md-right col-6 col-md-3 col-lg-3">€{item.cashback}</p>
                                </div>
                            </tr>
                        )
                    })}

                </div>
            </div>
            {showClaimModal && <ClaimCashbackComponent onClose={() => setShowClaimModal(false)}/>}
            {showPayoutModal && <PayoutModalComponent
                onClose={() => setShowPayoutModal(false)}
                sniptechBalance={sniptechBalance}
            />}
        </div>
    );

    function checkPayout() {
        if (sniptechBalance?.payableAmount === 0) {
            failedAlertFunction(initStrings.noBalanceForPayout, '');
            return;
        }
        setShowPayoutModal(true);
    }


    async function fetchMySniptechTransactionOnline() {
        try {
            let transactionResponse = await fetchMyTransactionSnipTechCall();
            let balancesResponse = await fetchSnipTechBalancesCall();

            if (transactionResponse && transactionResponse.length > 0) {
                setSniptechTransactions(transactionResponse)
            }
            setSniptechBalance(balancesResponse)
        } catch (e) {
            console.log(e)
        }
    }
}
