import React, {useState, useRef, useEffect} from 'react';
import {Animated} from "react-animated-css";
import {confirmMembershipCancellationCall} from "../../api/ApiCalls";
import {useParams} from "react-router-dom";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import KnaekPrimaryButton from "../../components/AppComponents/KnaekPrimaryButton";


export default function UnsubscriptionDone() {
    const [errorMessage,setErrorMessage] = useState('')
    const initStrings = {
        you_pay_now: stringsTranslate(appWords.you_pay_now),
        then: stringsTranslate(appWords.then),
        per_academic_year: stringsTranslate(appWords.per_academic_year),
        per_month: stringsTranslate(appWords.per_month),
        you_can_cancel: stringsTranslate(appWords.you_can_cancel),
        payment_canceled: stringsTranslate(appWords.payment_canceled),
        subscription_canceled: stringsTranslate(appWords.subscription_canceled),
        subscription_canceled_but_you_have_to_pay_1: stringsTranslate(appWords.subscription_canceled_but_you_have_to_pay_1),
        subscription_canceled_but_you_have_to_pay_2: stringsTranslate(appWords.subscription_canceled_but_you_have_to_pay_2),
        pay_direct: stringsTranslate(appWords.pay_direct),
        subscription_is_paid: stringsTranslate(appWords.subscription_is_paid),
        subscription_is_not_paid: stringsTranslate(appWords.subscription_is_not_paid),
        pay: stringsTranslate(appWords.pay),
        expiration_error_message: stringsTranslate(appWords.expiration_error_message)
    }


    const {token} = useParams();
    const [payment, setPayment]= useState(null)

    useEffect(() => {
        confirmMembershipCancellation()
    }, [])


    return (
        <div className="homepage-4 ">
            <div className="main">
                <div className="container">
                    {(payment) &&  <div className="row">
                        <Animated className={"col-12 bg-white pt-5 mt-5 d-flex justify-content-center align-items-center flex-wrap flex-column"}
                                  animationIn="bounceInLeft"
                                  animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                                  animationInDelay={100}>

                            <h2 className="text-knaek-primary mt-5 text-center">
                                {payment.paid? initStrings.subscription_canceled: (initStrings.subscription_canceled_but_you_have_to_pay_1+ payment.cancellation_date.toString().substring(0, 10)  + initStrings.subscription_canceled_but_you_have_to_pay_2)}
                            </h2>
                            <h4 className="text-knaek-title mt-4 text-center">
                                {payment.paid? initStrings.subscription_is_paid: (initStrings.subscription_is_not_paid + '€ '+payment.amount)}
                            </h4>


                            {!payment.paid &&   <KnaekPrimaryButton title={initStrings.pay_direct.toString().toUpperCase()}
                                                                    onClick={()=> window.open(payment.checkout_url)}
                                                                    className={"mt-4 mb-5 border-0"}
                            />}

                        </Animated>
                    </div>}
                    {errorMessage && <div className='row'>
                        <Animated className={"col-12 bg-white pt-5 mt-5 d-flex justify-content-center align-items-center flex-wrap flex-column"}
                                  animationIn="bounceInLeft"
                                  animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                                  animationInDelay={100}>

                            <h2 className="text-knaek-primary mt-5 text-center" dangerouslySetInnerHTML={{__html:errorMessage}} />
                        </Animated>
                    </div>}
                </div>
            </div>
        </div>
    );

    async function confirmMembershipCancellation() {
        try {
            let data = {token}
            let payment = await confirmMembershipCancellationCall(data)
            setPayment(payment)
        } catch (e) {
            console.log(e)
            if(e.response.data.payload.message[0] === "Membership cancellation is expired"){
                setErrorMessage(initStrings.expiration_error_message)
                return
            }
            setErrorMessage(e.response.data.payload.message[0])
            // validationErrorAlertFunction(initStrings.not_all_data_are_valid);
        }

    }


}
