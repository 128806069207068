import React, {useRef, useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {Animated} from "react-animated-css";
import {appWords} from "../../../config/Constants";
import CitySelectorHomePage from "../../AppCustomizedComponent/CitySelectorHomePage";



export default function BannerSectionHome() {
    const initStrings = {
        heading: stringsTranslate(appWords.discount_in_you_city),
        subheading: stringsTranslate(appWords.save_unlimited_money_in_more_than),
        playImg: "/assets/images/k-logo.png",
        // playImg: "/img/google-play-black.png",
        heroThumb: "/img/extra/lesoptijd_mobiel_nobg.png",
        appleStoreLink: process.env.REACT_APP_APPLE_STORE,
        playStoreLink: process.env.REACT_APP_PLAY_STORE,
        dropdownCitySelectTitle: stringsTranslate(appWords.your_student_city),
        dropdownCitySelectSubTitle:stringsTranslate(appWords.select_your_city),
        ofText: stringsTranslate(appWords.text_or),
        backgroundImg1: "../images/hamburger.png",
        backgroundImg2: "../images/book-left.png",
        backgroundImg3: "../images/coffee.png",
        backgroundImg4: "../images/book-right.png",
        backgroundImg5: "../images/pizza.png"
    }



    return (
        <section id="Home" className="section homepage-first-section align-items-start justify-content-center mt-1">
            <div className="container mb-5">
                <div className="row">
                    {/*Logo elements */}
                    <div className="col-12 text-center">
                        <div className="row d-flex align-items-center justify-content-center">

                            {/*Apple reduxStore link show on sm screen */}

                                <Animated className={"col-3 d-flex align-items-center justify-content-end d-block d-lg-none"} animationIn="bounceInLeft"
                                          animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationInDelay={100}>
                                <a className="badge p-3 bg-white shadow radius-100 " rel="noopener norefferer"
                                   href={"https://apps.apple.com/nl/app/knaek/id547383255?platform=iphone"}
                                   aria-label="Apple Store Button" target='_blank'>
                                    <svg className="" width="35" height="35"
                                         viewBox="0 0 32 32">
                                        <path
                                            d="M4.327,11.8c-2.8,4.7-1,11.8,2,16.1,1.5,2.2,3,4.1,5,4.1h.2a5.6,5.6,0,0,0,2.3-.6,5.787,5.787,0,0,1,2.8-.7,6.7,6.7,0,0,1,2.7.6,4.61,4.61,0,0,0,2.5.7c2.3-.1,3.8-2.2,5-4a17.216,17.216,0,0,0,2.2-4.5h0c.1-.1,0-.2-.1-.3h0a6.9,6.9,0,0,1-4.1-6.2,7,7,0,0,1,3.3-5.8h0c.1,0,.1-.1.1-.2v-.2a7.9,7.9,0,0,0-6-3.2h-.5a10.085,10.085,0,0,0-3.7.9,7.428,7.428,0,0,1-1.8.6,6.711,6.711,0,0,1-1.9-.6,8.38,8.38,0,0,0-3.2-.8h-.1A7.8,7.8,0,0,0,4.327,11.8Z"/>
                                        <path
                                            d="M22.227,0a7.5,7.5,0,0,0-4.8,2.5,6.6,6.6,0,0,0-1.7,5.1.2.2,0,0,0,.2.2h.4a6.2,6.2,0,0,0,4.5-2.3,7.2,7.2,0,0,0,1.7-5.3C22.527.1,22.427,0,22.227,0Z"/>
                                    </svg>
                                </a>
                            </Animated>
                            {/*Logo */}
                            <div className={"col-6"}>
                                <img src={"/assets/images/app-icon.png"} alt="" width="160" height="160"
                                     className="k-logo-homepage radius-40 "/>
                            </div>

                            {/*Play reduxStore link show on sm screen */}
                                <Animated className={"col-3 d-flex align-items-center justify-content-start d-block d-lg-none"} animationIn="bounceInRight"
                                          animationOut="fadeOut" isVisible={true} animationInDuration={1000} animationInDelay={100}>
                                <a className="badge p-3 bg-white shadow radius-100" rel="noopener norefferer"
                                   href={"https://play.google.com/store/apps/details?id=net.evect.knaek"}
                                   aria-label="Play Store Button" target='_blank'>
                                    <svg className="" width="30" height="30"
                                         viewBox="0 0 28.26 32">
                                        <path
                                            d="M17.83,14.68,3.47.28A2.77,2.77,0,0,1,6.13.41L22.53,10ZM2.15,1.61a3,3,0,0,0-.28,1.25V29.2a2.89,2.89,0,0,0,.28,1.24L16.51,16ZM3.49,31.75A2.92,2.92,0,0,0,4.68,32a2.8,2.8,0,0,0,1.45-.39L22.54,22l-4.7-4.71ZM28.78,13.6h0l-4.55-2.66L19.16,16l5.05,5.06,4.57-2.67a2.81,2.81,0,0,0,0-4.8Z"
                                            transform="translate(-1.87 0)"/>
                                    </svg>
                                </a>
                                </Animated>
                        </div>
                    </div>

                    {/*Text under the logo */}
                    <div className="col-12 text-center">
                        <h2 className="mt-4 mb-4">{initStrings.heading}</h2>
                        <h6 className="">{initStrings.subheading}</h6>
                    </div>

                    {/*City selector */}

                    <CitySelectorHomePage/>

                    {/*Background images*/}

                    {/*Left images*/}
                    <Animated className={"hamburger-image d-lg-block d-none"} animationIn="bounceInLeft"
                              animationOut="fadeOut" isVisible={true} animationInDuration={1500} animationInDelay={500}>
                        <img src={"../assets/images/hamburger.png"} alt={"/"}/>
                    </Animated>


                    {/*Bottom images*/}
                    <Animated className={"cards-left-image position-absolute d-lg-block d-none"}
                              animationIn="bounceInUp" animationOut="fadeOut" isVisible={true} animationInDuration={1500} animationInDelay={500}>
                        <img src={"../assets/images/cards-knaek.png"} alt={"/"}/>
                    </Animated>

                    <Animated className={"knaek-box-image position-absolute d-lg-block d-none"}
                              animationIn="bounceInUp" animationOut="fadeOut" isVisible={true} animationInDuration={1500} animationInDelay={500}>
                        <img src={"../assets/images/knaek-box.png"} alt={"/"}/>
                    </Animated>


                    {/*Right images*/}
                    <Animated className={"coffee-image-container d-lg-block d-none"} animationIn="bounceInRight"
                              animationOut="fadeOut" isVisible={true} animationInDuration={1500} animationInDelay={500}>
                        <img src={"../assets/images/coffee.webp"} className={"coffee-image"} alt="coffee-d"/>
                    </Animated>

                    <Animated className={"pizza-image-container d-lg-block d-none"} animationIn="bounceInRight"
                              animationOut="bounceInRight" isVisible={true} animationInDuration={1500} animationInDelay={500}>
                        <img src={"../assets/images/pizza.webp"} alt="pizza-image" className="pizza-image"/>
                    </Animated>


                    {/*Iphone placeholder */}
                    <div className={" col-12 d-flex justify-content-center cursor-pointer"} onClick={()=> window.location.href="/download-app"}>
                        <Animated className={"iphone-placeholder"} animationIn="bounceInUp"
                                  animationOut="fadeOut" isVisible={true} animationInDuration={1500} >

                        </Animated>
                    </div>


                    {/*OF element*/}
                    <div className="between-element1-homepage col-12 d-flex align-items-center justify-content-center" >
                        <a className={"section-title"}><p className={"of-element-text font-weight-bold"}>OF</p></a>
                    </div>
                </div>
            </div>

        </section>
    );

    function goToCityFilter(city){
        window.location = "/studentenkorting/"+city

    }
}
