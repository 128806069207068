import React, {useEffect, useState} from 'react';
import ImageComponent from "./ImageComponent";
import {useSelector} from "react-redux";
import {FaHeart} from "react-icons/fa";
import {FiHeart} from "react-icons/fi";
import {appUrls} from "../../config/Constants";
import {getBackgroundImageJobs, hasAValue} from "../../uitls/SharedFunctions";

export default function NewJobCard({item, hideFavorite = false}) {
    const [intiVariables, setIntiVariables]= useState(null)

    useEffect(() => {
        let intiVariablesLocal = {}
        if (hasAValue(item)) {
            intiVariablesLocal['id'] = item.id
            intiVariablesLocal['jobTitle'] = item.jobTitle
            intiVariablesLocal['jobSummary'] = item.jobSummary
            intiVariablesLocal['functionDescription'] = item.functionDescription
            intiVariablesLocal['aboutCompanyJob'] = item.aboutCompanyJob
            intiVariablesLocal['jobRequirements'] = item.jobRequirements
            intiVariablesLocal['jobOffer'] = item.jobOffer
            intiVariablesLocal['jobAdditionalInfo'] = item.jobAdditionalInfo
            intiVariablesLocal['jobMinHours'] = item.jobMinHours
            intiVariablesLocal['jobMaxHours'] = item.jobMaxHours
            intiVariablesLocal['jobMinSalary'] = item.jobMinSalary
            intiVariablesLocal['jobMaxSalary'] = item.jobMaxSalary
            intiVariablesLocal['postedAt'] = item.postedAt
            intiVariablesLocal['origin'] = item.origin
            intiVariablesLocal['company'] = item.company
            intiVariablesLocal['logo'] = hasAValue(item.logo)? item.logo : (item.origin.toString().includes("joppboard")? 'assets/images/Jopp/joppboard.png': 'assets/images/Jopp/Jopp-logo-500x500.jpg')
            intiVariablesLocal['background'] = hasAValue(item.background)? item.background : getBackgroundImageJobs(item)
        }
        setIntiVariables(intiVariablesLocal)

    }, [item])

    return (
        <div className={"card radius-15 border-0 w-100 store-discount-card cursor-pointer shadow"}
             onClick={() => goToInfo()}>
            {(hasAValue(intiVariables) && hasAValue(intiVariables.background))?
            <ImageComponent src={intiVariables.background} className="homepage-online-section-online-card-background" alt=""/> :
            <ImageComponent src={'assets/images/Jopp/jopp-img - Copy.png'} className="homepage-online-section-online-card-background" alt=""/>}

            {hasAValue(intiVariables) &&
                <div className="card-body row store-discount-card-body">
                <div className={"col-12 d-flex justify-content-center"}>
                    <div
                        className="bg-white radius-15 online-discount-card-logo shadow d-flex justify-content-center align-items-center">
                        {hasAValue(intiVariables.logo) && <ImageComponent src={intiVariables.logo}
                                        alt="" className={"online-discount-card-logo-img radius-15"}/>}
                    </div>


                </div>
                <div className={"col-12 text-center"}>
                    <h4 className="w-100 mt-1 text-knaek-title">{intiVariables.company}</h4>
                    <h6 className="w-100 text-knaek-regular mt-2" dangerouslySetInnerHTML={{__html: (intiVariables.jobTitle.toString().length < 50) ? intiVariables.jobTitle : intiVariables.jobTitle.toString().substring(0, 20) + " ...."}}></h6>
                </div>
            </div>}

        </div>
    );


    function goToInfo() {
        let jobTitle =  intiVariables.jobTitle.replace(/[^a-zA-Z0-9]/g, '');
        let company =  intiVariables.company.replace(/[^a-zA-Z0-9]/g, '');
        window.location.href = ("/bijbaan/"+company + "/" +jobTitle + "/" + intiVariables.id)
    }
}
