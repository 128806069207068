import React, {useEffect, useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {hasAValue} from "../../../uitls/SharedFunctions";
import {appWords} from "../../../config/Constants";
import {useDispatch, useSelector} from "react-redux";
import {updateOrderKnaekDataRS} from "../../../redux/reduxActions/reduxStoreActions";
import {
    validateName,
} from "../../../uitls/DataValidator";
import ArrowOrderWizardComponent from "./ArrowOrderWizardComponent";
import {validationErrorAlertFunction} from "../../../uitls/AlertFunctions";
import ImageComponent from "../../AppComponents/ImageComponent";
import {facebookTrackInitiateCheckout} from "../../../uitls/FacebookPixelEvents";
import {tiktokTrackInitiateCheckout} from "../../../uitls/TiktokPixelEvants";
import {postHitTrackingCall} from "../../../api/ApiCalls";



export default function OrderKnaekIDFlowStep2({currentStep, setCurrentStep}) {
    const orderKnaekDataRS = useSelector(state => state.orderKnaekDataRS);
    const dispatch = useDispatch()
    const [errorMessage, setErrorMessage] = useState(null);


    const initStrings = {
        order_details_heading: stringsTranslate(appWords.personal_details),
        we_only_ask_for: stringsTranslate(appWords.we_only_ask_for),
        first_name: stringsTranslate(appWords?.first_name).toString().toUpperCase(),
        last_name: stringsTranslate(appWords?.last_name).toString().toUpperCase(),
        enter_your_first_name: stringsTranslate(appWords.enter_your_first_name),
        enter_your_last_name: stringsTranslate(appWords.enter_your_last_name),
        enter_your_phone_number: stringsTranslate(appWords.enter_your_phone_number),
        repeat: stringsTranslate(appWords.repeat),
        first_name_not_valid: stringsTranslate(appWords.first_name_not_valid),
        last_name_not_valid: stringsTranslate(appWords.last_name_not_valid),
        not_all_data_are_valid: stringsTranslate(appWords.not_all_data_are_valid),
    }

    useEffect(() => {
        postHitTrackingCall({event: `order-journey-2-be`});
    }, [currentStep]);

    useEffect(() => {
        facebookTrackInitiateCheckout()
        tiktokTrackInitiateCheckout()
    }, [])


    useEffect(() => {
    }, [orderKnaekDataRS, errorMessage])



    return (
        <section className="section align-items-start justify-content-start mt-5  w-100"         style={{zIndex:"-1"}}
        >
            <div className="container-fluid mt-5">
                <div className="row">
                    <ImageComponent src={"./assets/images/knaek-banner.png"} alt=""/>
                    <div className="col-md-12 col-sm-12 order-details-container">

                        <div className={"text-left mt-5 mb-3"}>
                            <h4 className="my-3">{initStrings.order_details_heading}</h4>
                            <p className={"mt-4 mb-3"}>{initStrings.we_only_ask_for}</p>
                        </div>


                        <div className="row">
                            <div className="col-12 d-flex">
                                <ul className="col-12 list-group list-group-flush">
                                    <li className="list-group-item radius-15 contact-form-fields-div border-0 order-knaek-input-style">
                                        <label>{initStrings.first_name}</label>
                                        <input name="firstName" type="text"
                                               value={(hasAValue(orderKnaekDataRS) && orderKnaekDataRS['firstName'])? orderKnaekDataRS['firstName'] : ''}
                                               className="form-control bg-transparent shadow-none"
                                               placeholder={initStrings.enter_your_first_name}
                                               required="required"
                                               onChange={handleInputChange}
                                        />
                                    </li>
                                    {((hasAValue(errorMessage) && errorMessage['firstName'] ) && errorMessage['firstName']) && <span className={"text-danger mt-2 ml-4 font-size-12"}>{initStrings.first_name_not_valid}</span>}


                                    <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                                        <label>{initStrings.last_name}</label>
                                        <input name="lastName" type="text"
                                               value={(hasAValue(orderKnaekDataRS) && orderKnaekDataRS['lastName'])? orderKnaekDataRS['lastName'] : ''}
                                               className="form-control bg-transparent shadow-none"
                                               placeholder={initStrings.enter_your_last_name}
                                               required="required"
                                               onChange={handleInputChange}
                                        />
                                    </li>
                                    {((hasAValue(errorMessage) && errorMessage['lastName'] ) && errorMessage['lastName']) && <span className={"text-danger mt-2 ml-4 font-size-12"}>{initStrings.last_name_not_valid}</span>}

                                </ul>
                            </div>
                        </div>
                    </div>
                    <ArrowOrderWizardComponent handleNext={() => handleNext()} handleBack={() => handleBack()}
                                               currentStep={currentStep}/>
                </div>
            </div>
        </section>
    );

    function handlePaste (event)  {
        event.preventDefault(); // Prevent pasting data
    }


    async function handleInputChange(event) {
        const {name, value} = event.target;
        let updatedData = { ...orderKnaekDataRS, [name]:  value };
        dispatch(updateOrderKnaekDataRS(updatedData));
    }


    async function handleNext() {
        setErrorMessage(null)

        if (!hasAValue(orderKnaekDataRS)) {
            setErrorMessage((errorMessage) => ({ ...errorMessage, firstName: true }));
            setErrorMessage((errorMessage) => ({ ...errorMessage, lastName: true }));
            validationErrorAlertFunction(initStrings.not_all_data_are_valid);
            return;
        }


        const requiredFields = ["firstName", "lastName"];
        let isError = false;

        for (const field of requiredFields) {
            if (!hasAValue(orderKnaekDataRS[field])) {
                isError = true;
                setErrorMessage((errorMessage) => ({ ...errorMessage, [field]: true }));
            }
        }

        if (!isError) {
            const isFirstNameValid = validateName(orderKnaekDataRS["firstName"]);
            const isLastNameValid = validateName(orderKnaekDataRS["lastName"]);

            if ( isFirstNameValid && isLastNameValid && currentStep !== 3) {
                setCurrentStep((currentStep) => currentStep + 1);
            } else {
                if (!isFirstNameValid) {
                    setErrorMessage((errorMessage) => ({ ...errorMessage, firstName: true }));
                }
                if (!isLastNameValid) {
                    setErrorMessage((errorMessage) => ({ ...errorMessage, lastName: true }));
                }

                validationErrorAlertFunction(initStrings.not_all_data_are_valid)
            }
        } else {
            validationErrorAlertFunction(initStrings.not_all_data_are_valid)
        }
    }



    function handleBack() {
        if(currentStep !== 1)
            setCurrentStep(currentStep-1)
    }
}
