import React, {useRef, useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";



export default function TextSectionForCompaies() {


    const initStrings = {
        heading1: stringsTranslate(appWords.discover_the_benefits),
        subheading1: stringsTranslate(appWords.knaek_selects_each_college_year_the_25),
        content1: stringsTranslate(appWords.in_the_past_years_we_have_tested_hundreds),
        playImg1: "/assets/images/studentkorting/people.png",
        heading2: stringsTranslate(appWords.the_edition),
        subheading2: stringsTranslate(appWords.knaek_reaches_an_annual_an_enormous_group_of_students),
        playImg2: "/assets/images/studentkorting/oplage.png",
        heading3: stringsTranslate(appWords.prices),
        subheading3: stringsTranslate(appWords.at_knaek_you_dont_have_to_spend_a_fortune_to_get_promoted),
        playImg3: "/assets/images/studentkorting/kossten1.png",
    }

    return (
        <section className="section mt-4 pt-2 mb-0">
            <div className="container">
                <div className="row mt-5">
                    {/*Text under the logo */}

                    <div className="col-lg-6 col-md-12">
                        <img src={initStrings.playImg1} alt={""}/>
                    </div>
                    <div className="col-lg-6 col-md-12 flex-wrap d-flex   align-items-center">
                        <h2 className={"m-3 p-3"}>{initStrings.heading1}
                            <p className={"mt-2 p-1 line-text"}>{initStrings.subheading1}</p>
                            <p className={"pt-3 p-1 line-text"}>{initStrings.content1}</p></h2>

                    </div>

                </div>

                <div className="row mt-5">
                    {/*Text under the logo */}
                    <div className="col-lg-6 col-md-12 flex-wrap d-flex align-items-center">
                        <h2 className={"m-3 p-3"}>{initStrings.heading2}
                            <p className={"mt-2 p-1 line-text"}>{initStrings.subheading2}</p></h2>

                    </div>
                    <div className="col-lg-6 col-md-12">
                        <img src={initStrings.playImg2} alt={""}/>
                    </div>
                </div>

                <div className="row mt-5">
                    {/*Text under the logo */}

                    <div className="col-lg-6 col-md-12">
                        <img src={initStrings.playImg3} alt={""}/>
                    </div>
                    <div className="col-lg-6 col-md-12 flex-wrap d-flex align-items-center">
                        <h2 className={"m-3 p-3 mt-5"}>{initStrings.heading3}
                            <p className={"mt-2 p-1 line-text"}>{initStrings.subheading3}</p></h2>
                    </div>
                </div>
            </div>
        </section>
    );
}
