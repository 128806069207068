import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {updateOrderKnaekDataRS} from "../../../redux/reduxActions/reduxStoreActions";
import {Animated} from "react-animated-css";
import OrderKnaekIDFlowStep0 from "./OrderKnaekIDFlowStep0";
import OrderKnaekIDFlowStep1 from "./OrderKnaekIDFlowStep1";
import OrderKnaekIDFlowStep2 from "./OrderKnaekIDFlowStep2";
import OrderKnaekIDFlowStep3 from "./OrderKnaekIDFlowStep3";
import {useLocation} from "react-router-dom";
import {postHitTrackingCall} from "../../../api/ApiCalls";



export default function OrderComponent({backgroundColor, withStudentEmail}) {
    const dispatch = useDispatch()
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    let chooseCountry = 0
    if(window.location.href.includes("bestellen") && params.size > 0){
        chooseCountry = 1
    }else if(window.location.href.includes("intro")){
        chooseCountry = 1
    }else{
        chooseCountry = 0
    }
    const [currentStep, setCurrentStep] = useState(chooseCountry);



    useEffect(() => {
        dispatch(updateOrderKnaekDataRS(null))
    }, [])
    console.log(currentStep)
    return (
        <>
            {currentStep === 0 &&
                <Animated className={"row bg-white h-100vh order-slider-section"}
                          animationIn="bounceInLeft"
                          animationOut="fadeOut" isVisible={(currentStep === 0)} animationInDuration={1000}
                          animationInDelay={100}>
                    <OrderKnaekIDFlowStep0 currentStep={currentStep} setCurrentStep={(val) => setCurrentStep(val)} withStudentEmail={ withStudentEmail}/>
                </Animated>}
            {currentStep === 1 &&
                <Animated className={`row h-100vh order-slider-section  w-100 ${backgroundColor}` }
                          animationIn="bounceInLeft"
                          animationOut="fadeOut" isVisible={(currentStep === 1)} animationInDuration={1000}
                          animationInDelay={100}>
                    <OrderKnaekIDFlowStep1 currentStep={currentStep} setCurrentStep={(val) => setCurrentStep(val)}  withStudentEmail={ withStudentEmail}/>
                </Animated>}
            {currentStep === 2 &&
                <Animated className={`row h-100vh order-slider-section w-100 ${backgroundColor}`}
                          animationIn="bounceInLeft"
                          animationOut="fadeOut" isVisible={(currentStep === 2)} animationInDuration={1000}
                          animationInDelay={100}>
                    <OrderKnaekIDFlowStep2 currentStep={currentStep} setCurrentStep={(val) => setCurrentStep(val)} withStudentEmail={ withStudentEmail}/>
                </Animated>}

            {currentStep === 3 &&
                <Animated className={`row h-100vh order-slider-section w-100 ${backgroundColor}`}
                          animationIn="bounceInLeft"
                          animationOut="fadeOut" isVisible={(currentStep === 3)} animationInDuration={1000}
                          animationInDelay={100}>
                    <OrderKnaekIDFlowStep3 currentStep={currentStep} setCurrentStep={(val) => setCurrentStep(val)} withStudentEmail={ withStudentEmail}/>
                </Animated>}
        </>

    );
}

