export const UPDATE_STORE_DISCOUNTS = 'UPDATE_STORE_DISCOUNTS';
export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
export const UPDATE_CITIES = 'UPDATE_CITIES';
export const UPDATE_JOBS = 'UPDATE_JOBS';
export const UPDATE_SHOW_AUTH_MODAL = 'UPDATE_SHOW_AUTH_MODAL';
export const UPDATE_SHOW_LOGIN_LINK_MODAL = 'UPDATE_SHOW_LOGIN_LINK_MODAL';
export const UPDATE_AUTH_DATA = 'UPDATE_AUTH_DATA';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_ORDER_KNAEK_DATA = 'UPDATE_ORDER_KNAEK_DATA';
export const UPDATE_ONLINE_DISCOUNTS_SnipTech = 'UPDATE_ONLINE_DISCOUNTS_SnipTech';
export const UPDATE_SNIP_TECH_STORES_COUNT = 'UPDATE_SNIP_TECH_STORES_COUNT';
export const UPDATE_AUTH_DATA_NEW = 'UPDATE_AUTH_DATA_NEW';
export const UPDATE_EMAIL_IN_MODAL = 'UPDATE_EMAIL_IN_MODAL';


export const updateStoreDiscountsRS = (list) => ({ type: UPDATE_STORE_DISCOUNTS, list });
export const updateJobsRS = (list) => ({ type: UPDATE_JOBS, list });
export const updateLocationsRS = (list) => ({ type: UPDATE_LOCATIONS, list });
export const updateCitiesRS = (list) => ({ type: UPDATE_CITIES, list });
export const updateShowAuthModalRS = (val) => ({ type: UPDATE_SHOW_AUTH_MODAL, val });
export const updateAuthDataRS = (val) => ({ type: UPDATE_AUTH_DATA, val });
export const updateLanguageRS = (val) => ({ type: UPDATE_LANGUAGE, val });
export const updateOrderKnaekDataRS = (val) => ({ type: UPDATE_ORDER_KNAEK_DATA, val });
export const updateOnlineDiscountsSnipTechRS = (list) => ({ type: UPDATE_ONLINE_DISCOUNTS_SnipTech, list });
export const updateSnipTechStoresCountRS = (val) => ({ type: UPDATE_SNIP_TECH_STORES_COUNT, val });
export const updateAuthDataNEWRS = (val) => ({ type: UPDATE_AUTH_DATA_NEW, val });
export const updateEmailInModal = (val) => ({type: UPDATE_EMAIL_IN_MODAL, email:val});
export const updateShowLoginLinkModal = (val) => ({ type: UPDATE_SHOW_LOGIN_LINK_MODAL, val });
