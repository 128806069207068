import React, {useEffect, useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";


export default function CancellingMembershipFlowStep2({hasSubscription}) {


    const initStrings = {
        please_confirm_your_cancellation: stringsTranslate(appWords.please_confirm_your_cancellation),
        we_got_notification_with_your_request: stringsTranslate(appWords.we_got_notification_with_your_request),
        we_guss_that_you_dont_have_sub: stringsTranslate(appWords.we_guss_that_you_dont_have_sub),
        subscription_cancelled_title: stringsTranslate(appWords.subscription_cancelled_title),
        we_are_sorry_to: stringsTranslate(appWords.we_are_sorry_to),
        to_home: stringsTranslate(appWords.to_home),

    }

    return (
        <section className="section align-items-center justify-content-center mt-5">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12 bg-white">
                        <div className={"text-left mt-5 mb-3 bg-gray p-5 radius-25"}>
                            {hasSubscription && <h1 className="my-3">{initStrings.subscription_cancelled_title}</h1>}
                            {!hasSubscription && <h1 className="my-3">{initStrings.we_guss_that_you_dont_have_sub}</h1>}
                            {hasSubscription && <p className={"mt-4 mb-3"}>{initStrings.please_confirm_your_cancellation}</p>}
                            {!hasSubscription && <p className={"mt-4 mb-3"}>{initStrings.we_got_notification_with_your_request}</p>}
                          <div className="d-flex justify-content-end align-content-center">
                                <KnaekPrimaryButton title={initStrings.to_home} className=" m-1"
                                                    onClick={() => window.location.href = "/"}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}
