import React from "react";
import BannerSectionForCompaies from "../../components/AppSections/PartnersOfKnaek/BannerSectionForCompaies";
import BetweenSectionForCompanies from "../../components/AppSections/ForCompanies/BetweenSectionForCompanies";
import TextSectionForCompaies from "../../components/AppSections/ForCompanies/TextSectionForCompaies";
import FooterSection from "../../components/FooterSection/Footer";
import KnaekIDUsers from "../../components/AppSections/ForCompanies/KnaekIDUsers";
import CTACompanies from "../../components/AppSections/HomepageSections/CTACompanies";


export default function ForCompanies() {
    return (
        <div className={"homepage-4 pt-5 mt-5"}>
            <div className={"main"}>
                <BannerSectionForCompaies/>
                <BetweenSectionForCompanies/>
                <KnaekIDUsers/>
                <TextSectionForCompaies/>
                <CTACompanies/>
                <FooterSection/>
            </div>
        </div>
    );
}
