import React, {useState, useRef, useEffect} from 'react';
import {Animated} from "react-animated-css";
import {checkEmailVerificationCall, postHitTrackingCall} from "../../api/ApiCalls";
import {useParams} from "react-router-dom";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {waitAlertFunction} from "../../uitls/AlertFunctions";


export default function OrderDone() {
    const initStrings = {
        playImg: "/assets/images/knaek/emoticon_congratulations-0a9f8fa3dd889c15cb26972d26babee701d176cbb503f0ed6e2d5ad76bf94c5a.png",
        you_are_now_a_member_from_knaek: stringsTranslate(appWords.you_are_now_a_member_from_knaek),
        your_email_is_verfied_successfully: stringsTranslate(appWords.your_email_is_verfied_successfully),
        with_knaek_id_you_can_activate: stringsTranslate(appWords.with_knaek_id_you_can_activate),
        verification_error: stringsTranslate(appWords.verification_error),
        invalid_verification_link: stringsTranslate(appWords.invalid_verification_link),
        image: "/assets/images/Knaek_mockup.webp",
    }


    const {token} = useParams();
    const [verified, setVerified]= useState(null)

    useEffect(() => {
        checkStatusOrder()
    }, [])


    return (
    <div className="homepage-4 ">
        <div className="main">
            <div className="container-fluid">
                {(verified  && verified.done) &&  <div className="row">
               <Animated className={"col-md-5 col-lg-5 col-sm-12 bg-white ptb_100 mt-5 d-flex justify-content-center align-items-center flex-wrap flex-column"}
                                                    animationIn="bounceInLeft"
                                                    animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                                                    animationInDelay={100}>
                   <img className="order-done-img mb-3" src={initStrings.playImg} alt=""/>
                   <h2 className="text-knaek-primary text-center">
                       {initStrings.your_email_is_verfied_successfully}
                   </h2>
                    </Animated>

                    <div className="col-md-7 col-lg-7 col-sm-12 bg-gradient ptb_100 d-flex justify-content-center mt-5">
                        <img className={"px-3"} src={initStrings.image} width={450} alt=""/>
                    </div>
                </div>}

                {(verified && !verified.done) &&  <div className="row">
                    <Animated className={"col-12 bg-white pt-5 mt-5 d-flex justify-content-center align-items-center flex-wrap flex-column"}
                              animationIn="bounceInLeft"
                              animationOut="fadeOut" isVisible={true} animationInDuration={1000}
                              animationInDelay={100}>

                        <h2 className="text-knaek-primary mt-2 text-center">
                            {initStrings.verification_error}
                        </h2>
                        <h4 className="text-knaek-title mt-2 text-center">
                            {initStrings.invalid_verification_link}
                        </h4>

                    </Animated>
                </div>}
            </div>
        </div>
    </div>
    );

    async function checkStatusOrder() {
        waitAlertFunction(stringsTranslate(appWords.verifying_your_email))

        await new Promise(resolve => setTimeout(resolve, 5000));

        try {
            let response = await checkEmailVerificationCall(token);
            setVerified({done:true});
        } catch (e) {
            setVerified({done:false});
            console.log(e);

        }

    }


}
