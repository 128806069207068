import React from 'react';
import TermsAndConditionsContent
    from "../../components/AppSections/TermsAndConditionsSections/TermsAndConditionsContent";
import RequestYourKnaekID from "../../components/AppSections/HomepageSections/RequestYourKnaekID";
import FooterSection from "../../components/FooterSection/Footer";

export default function TermsAndConditions() {
    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <TermsAndConditionsContent />
                <RequestYourKnaekID />
                <FooterSection/>
            </div>
        </div>
    );
}
