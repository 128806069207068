import React from 'react';
import SnipTechBannerSectionOnlineDiscounts
    from "../../components/AppSections/SniptechOnlineDiscountsPageSections/SniptechBannerSectionOnlineDiscounts";
import SnipTechOnlineDiscountsPart
    from "../../components/AppSections/SniptechOnlineDiscountsPageSections/SniptechOnlineDiscountsPart";


export default function SnipTechOnlineDiscountsPage() {
    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <SnipTechBannerSectionOnlineDiscounts/>
                <SnipTechOnlineDiscountsPart/>
            </div>
        </div>
    );
}
