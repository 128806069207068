import React, { useState, useRef, useEffect } from "react";
import { Animated } from "react-animated-css";
import { useParams } from "react-router-dom";
import { stringsTranslate } from "../../locales/CustomTranslater";
import { appWords } from "../../config/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
    checkAvailabilityValidityDataCall,
    createOrderCall,
    getListPaymentMethodsCall, postHitTrackingCall,
    updateOrder,
} from "../../api/ApiCalls";
import {
    closeAlertFunction,
    loadingAlertFunction,
    validationErrorAlertFunction,
} from "../../uitls/AlertFunctions";
import { hasAValue } from "../../uitls/SharedFunctions";
import KnaekPrimaryButton from "../../components/AppComponents/KnaekPrimaryButton";

export default function Checkout() {
    const params = useParams();
    const orderKnaekDataRS = useSelector((state) => state.orderKnaekDataRS);
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(null);
    const [listPaymentMethods1, setListPaymentMethods1] = useState([]);
    const [listPaymentMethods2, setListPaymentMethods2] = useState([]);
    const [order, setOrder] = useState(null);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [showListMethods1, setShowListMethods1] = useState(true);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [isAppOrder, setIsAppOrder] = useState(false);
    const [isAppOrder2Tracking, setIsAppOrder2Tracking] = useState(false);
    const [formData, setFormData] = useState({
        token: "",
        firstName: "",
        lastName: "",
        studentEmail: "",
        email: "",
    });

    const initStrings = {
        order_knaek_id: stringsTranslate(appWords.order_knaek_id),
        update_order: stringsTranslate(appWords.update_order),
        you_are_a_student_at_which_1: stringsTranslate(
            appWords.you_are_a_student_at_which_1
        ),
        you_are_a_student_at_which_2: stringsTranslate(
            appWords.you_are_a_student_at_which_2
        ),
        you_are_a_student_at_which_3: stringsTranslate(
            appWords.you_are_a_student_at_which_3
        ),
        you_are_a_student_at_which_4: stringsTranslate(
            appWords.you_are_a_student_at_which_4
        ),
        you_are_a_student_at_which_5: stringsTranslate(
            appWords.you_are_a_student_at_which_5
        ),
        you_are_a_student_at_which_6: stringsTranslate(
            appWords.you_are_a_student_at_which_6
        ),
        you_can_cancel_till: stringsTranslate(appWords.you_can_cancel_till),
        this_website_is_safe: stringsTranslate(appWords.this_website_is_safe),
        price_1: "€0.02",
        price_2: "€30",
        not_all_data_are_valid: stringsTranslate(appWords.not_all_data_are_valid),
        others: stringsTranslate(appWords.others),
        back: stringsTranslate(appWords.back),
        first_name: stringsTranslate(appWords.first_name).toString().toUpperCase(),
        last_name: stringsTranslate(appWords.last_name).toString().toUpperCase(),
        email: stringsTranslate(appWords.email).toString().toUpperCase(),
        student_email: stringsTranslate(appWords.student_email)
            .toString()
            .toUpperCase(),
        country_code_label: stringsTranslate(appWords.country_code_label)
            .toString()
            .toUpperCase(),
        phone_number_label: stringsTranslate(appWords.phone_number_label)
            .toString()
            .toUpperCase(),
        i_agree_to_the_terms_and_conditions_part1: stringsTranslate(
            appWords.i_agree_to_the_terms_and_conditions_part1
        ),
        personal_details: stringsTranslate(appWords.account_info),
        account_info_not_correct: stringsTranslate(
            appWords.account_info_not_correct
        ),
        at: stringsTranslate(appWords.at),
        email_private: stringsTranslate(appWords.email_private),
        email_at_school: stringsTranslate(appWords.email_at_school),
        student_email_not_good: stringsTranslate(appWords.student_email_not_good),
        email_not_good: stringsTranslate(appWords.email_not_good),
        first_name_not_good: stringsTranslate(appWords.first_name_not_good),
        last_name_not_good: stringsTranslate(appWords.last_name_not_good),
    };

    const updateMembershipOrder = async () => {
        setErrorMessage(null);
        try {
            setErrorMessage(null)
            await updateOrder(formData);
            setIsAppOrder(false);
        } catch (err) {
            const messages = err?.response?.data?.payload?.message;
            if (messages && Array.isArray(messages)) {
                messages.forEach((message) => {
                    if (typeof message === "string") {
                        if (message.includes("studentEmail")) {
                            setErrorMessage((prevErrorMessage) => ({
                                ...prevErrorMessage,
                                studentEmail: true,
                            }));
                        } else if (message.includes("email")) {
                            setErrorMessage((prevErrorMessage) => ({
                                ...prevErrorMessage,
                                email: true,
                            }));
                        } else if (message.includes("firstName")) {
                            setErrorMessage((prevErrorMessage) => ({
                                ...prevErrorMessage,
                                firstName: true,
                            }));
                        } else if (message.includes("lastName")) {
                            setErrorMessage((prevErrorMessage) => ({
                                ...prevErrorMessage,
                                lastName: true,
                            }));
                        }
                    }
                });
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        checkToken();
        getAllPaymentMethods();
        // checkAvailabilityValidityData()
    }, []);

    useEffect(() => {
        setFormData({
            token: params?.token,
            firstName: order?.first_name,
            lastName: order?.last_name,
            studentEmail: order?.student_email,
            email: order?.email,
        });
    }, [order]);

    return (
        <div className="homepage-4 ">
            <div className="main">
                <div className="container-fluid">
                    <Animated
                        className={
                            "d-flex bg-white h-100vh order-slider-section justify-content-center "
                        }
                        animationIn="bounceInLeft"
                        animationOut="fadeOut"
                        isVisible={true}
                        animationInDuration={1000}
                        animationInDelay={100}
                    >
                        <div className={"col-11"}>
                            <section className="section align-items-start justify-content-start mt-5">
                                <div className="container-fluid mt-5">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12">
                                            <div className={"text-left mt-5 mb-3"}>
                                                <h1 className="my-3">{initStrings.order_knaek_id}</h1>
                                                <p className={"mt-4 mb-3"}>
                                                    {initStrings.you_are_a_student_at_which_1}
                                                    {order?.domain &&
                                                        " " +
                                                        initStrings.at +
                                                        " " +
                                                        (order?.school ? order?.school : order?.domain)}
                                                    {initStrings.you_are_a_student_at_which_2}
                                                    <a className={"text-primary font-weight-bold"}>
                                                        {" "}
                                                        {initStrings.price_1 + "  "}
                                                        {"  "}
                                                    </a>
                                                    {" "+initStrings.you_are_a_student_at_which_3}
                                                    {initStrings.you_are_a_student_at_which_4}
                                                    {/*{new Date().getFullYear()+". "}*/}
                                                    {initStrings.you_are_a_student_at_which_5}
                                                    <a className={"text-primary font-weight-bold"}>
                                                        {" "}
                                                        {initStrings.price_2 + " "}
                                                    </a>
                                                    {initStrings.you_are_a_student_at_which_6}
                                                </p>

                                                <p className={"mt-4 mb-3"}>
                                                    {initStrings.this_website_is_safe}
                                                </p>
                                            </div>

                                            <div className="row">
                                                {isAppOrder && (
                                                    <div className="col-12 my-3 d-flex flex-column flex-wrap justify-content-start align-items-center">
                                                        <h3 className="mb-0 mr-2" style={{ width: "100%" }}>
                                                            {initStrings.personal_details}
                                                        </h3>
                                                    </div>
                                                )}

                                                <Animated
                                                    className="col-12 d-flex"
                                                    animationIn="bounceInLeft"
                                                    animationOut="fadeOut"
                                                    isVisible={true}
                                                    animationInDuration={1000}
                                                    animationInDelay={100}
                                                >
                                                    <ul className="col-12 list-group list-group-flush">
                                                        <li className="list-group-item radius-15 contact-form-fields-div border-0 order-knaek-input-style">
                                                            <label>{initStrings.email_private}</label>
                                                            <input
                                                                name="email"
                                                                type="email"
                                                                value={formData.email}
                                                                className="form-control bg-transparent shadow-none"
                                                                onChange={handleInputChange}
                                                                disabled={!isAppOrder}
                                                            />
                                                        </li>
                                                        {hasAValue(errorMessage) &&
                                                            errorMessage["email"] &&
                                                            errorMessage["email"] && (
                                                                <span
                                                                    className={
                                                                        "text-danger mt-2 ml-2 font-size-12"
                                                                    }
                                                                >
                                  {initStrings.email_not_good}
                                </span>
                                                            )}
                                                        {/* <h5 className="m-2 text-danger" style={{width:"100%"}}>{errorMessage}</h5> */}

                                                        <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                                                            <label>{initStrings.student_email}</label>
                                                            <input
                                                                name="studentEmail"
                                                                type="email"
                                                                value={formData.studentEmail}
                                                                className="form-control bg-transparent shadow-none"
                                                                onChange={handleInputChange}
                                                                disabled={!isAppOrder}

                                                            />
                                                        </li>
                                                        {hasAValue(errorMessage) &&
                                                            errorMessage["studentEmail"] &&
                                                            errorMessage["studentEmail"] && (
                                                                <span
                                                                    className={
                                                                        "text-danger mt-2 ml-2 font-size-12"
                                                                    }
                                                                >
                                  {initStrings.student_email_not_good}
                                </span>
                                                            )}
                                                        {/* <h5 className="m-2 text-danger" style={{width:"100%"}}>{errorMessage}</h5> */}

                                                        {/* <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                                                            <label>{initStrings.country_code_label}</label>
                                                            <input name="countryCode" type="email"
                                                                   value={order?.country_code}
                                                                   className="form-control bg-transparent shadow-none"
                                                                   disabled
                                                            />
                                                        </li> */}
                                                        <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                                                            <label>{initStrings.phone_number_label}</label>
                                                            <input
                                                                name="phoneNumber"
                                                                type="email"
                                                                value={order?.phone_number}
                                                                className="form-control bg-transparent shadow-none"
                                                                disabled
                                                            />
                                                        </li>

                                                        <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                                                            <label>{initStrings.first_name}</label>
                                                            <input
                                                                name="firstName"
                                                                type="email"
                                                                value={formData.firstName}
                                                                className="form-control bg-transparent shadow-none"
                                                                onChange={handleInputChange}
                                                                disabled={!isAppOrder}

                                                            />
                                                        </li>
                                                        {hasAValue(errorMessage) &&
                                                            errorMessage["firstName"] &&
                                                            errorMessage["firstName"] && (
                                                                <span
                                                                    className={
                                                                        "text-danger mt-2 ml-2 font-size-12"
                                                                    }
                                                                >
                                  {initStrings.first_name_not_good}
                                </span>
                                                            )}
                                                        <li className="list-group-item radius-15 contact-form-fields-div border-0 mt-4 order-knaek-input-style">
                                                            <label>{initStrings.last_name}</label>
                                                            <input
                                                                name="lastName"
                                                                type="email"
                                                                value={formData.lastName}
                                                                className="form-control bg-transparent shadow-none"
                                                                onChange={handleInputChange}
                                                                disabled={!isAppOrder}
                                                            />
                                                        </li>
                                                        {hasAValue(errorMessage) &&
                                                            errorMessage["lastName"] &&
                                                            errorMessage["lastName"] && (
                                                                <span
                                                                    className={
                                                                        "text-danger mt-2 ml-2 font-size-12"
                                                                    }
                                                                >
                                  {initStrings.last_name_not_good}
                                </span>
                                                            )}
                                                        {isAppOrder && (
                                                            <div className={"row mt-4"}>
                                                                <div
                                                                    className={
                                                                        "col-12 d-flex justify-content-center align-items-start"
                                                                    }
                                                                >
                                                                    <input
                                                                        className={
                                                                            "check-box-order-page rounded-circle cursor-pointer p-2"
                                                                        }
                                                                        type="radio"
                                                                        name="agreeToTerms"
                                                                        onClick={(val) => {
                                                                            setAgreeToTerms(!agreeToTerms);
                                                                        }}
                                                                        disabled={agreeToTerms}
                                                                        checked={agreeToTerms}
                                                                    />
                                                                    <label
                                                                        className={
                                                                            " pl-2 cursor-pointer order-page-checkbox-text"
                                                                        }
                                                                        onClick={(val) => {
                                                                            if (!agreeToTerms) {
                                                                                setAgreeToTerms(!agreeToTerms);
                                                                            }
                                                                        }}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                            initStrings.i_agree_to_the_terms_and_conditions_part1,
                                                                        }}
                                                                    ></label>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {isAppOrder && (
                                                            <li className="list-group-item radius-15 border-0 mt-4 ">
                                                                <div
                                                                    className={"row justify-content-center"}
                                                                    style={{ gap: "100px" }}
                                                                >
                                                                    <KnaekPrimaryButton
                                                                        disabled={!agreeToTerms}
                                                                        onClick={() => {
                                                                            updateMembershipOrder();
                                                                        }}
                                                                        className={"mt-2 mb-4"}
                                                                        title={initStrings.order_knaek_id
                                                                            .toString()
                                                                            .toUpperCase()}
                                                                    />
                                                                    {/* <KnaekPrimaryButton
                                                                    disabled={!agreeToTerms}
                                                                    onClick={() => updateMembershipOrder()}
                                                                    className={"mt-2 mb-4"}
                                                                    title={(initStrings.update_order).toString().toUpperCase()}
                                                                /> */}
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </Animated>

                                                {!isAppOrder && (
                                                    <Animated
                                                        animationIn="bounceInLeft"
                                                        animationOut="fadeOut"
                                                        isVisible={true}
                                                        animationInDuration={1000}
                                                        animationInDelay={100}
                                                        className="col-12"
                                                    >
                                                        {showListMethods1 && (
                                                            <ul className="d-flex flex-wrap justify-content-start pl-0 ml-0">
                                                                {listPaymentMethods1.map((item, i) => {
                                                                    return (
                                                                        <li
                                                                            className="col-12 col-lg-6 my-3 d-flex justify-content-between align-items-center"
                                                                            key={i}
                                                                            onClick={() => {
                                                                                startPayment(item);
                                                                            }}
                                                                        >
                                                                            <div className="radius-15 p-3 w-100 border cursor-pointer d-flex flex-wrap justify-content-between align-items-center">
                                                                                <img
                                                                                    src={item.image}
                                                                                    alt=""
                                                                                    className={"payment-methods-img"}
                                                                                />
                                                                                <label
                                                                                    className={"cursor-pointer mr-4"}
                                                                                >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}

                                                                <li
                                                                    className="col-12 my-3 d-flex justify-content-between align-items-center"
                                                                    onClick={() => {
                                                                        setShowListMethods1(!showListMethods1);
                                                                    }}
                                                                >
                                                                    <div className="radius-15 other-button p-3 w-100 border cursor-pointer d-flex flex-wrap justify-content-center align-items-center">
                                                                        <label className={"cursor-pointer py-3"}>
                                                                            {showListMethods1
                                                                                ? initStrings.others
                                                                                : initStrings.back}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )}

                                                        {!showListMethods1 && (
                                                            <ul className="d-flex flex-wrap justify-content-start pl-0 ml-0">
                                                                {listPaymentMethods2.map((item, i) => {
                                                                    return (
                                                                        <li
                                                                            className="col-12 col-lg-6 my-3 d-flex justify-content-between align-items-center"
                                                                            key={i}
                                                                            onClick={() => {
                                                                                startPayment(item);
                                                                            }}
                                                                        >
                                                                            <div className="radius-15 p-3 w-100 border cursor-pointer d-flex flex-wrap justify-content-between align-items-center">
                                                                                <img
                                                                                    src={item.image}
                                                                                    alt=""
                                                                                    className={"payment-methods-img"}
                                                                                />
                                                                                <label
                                                                                    className={"cursor-pointer mr-4"}
                                                                                >
                                                                                    {item.name}
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}

                                                                <li
                                                                    className="col-12 my-3 d-flex justify-content-between align-items-center"
                                                                    onClick={() => {
                                                                        setShowListMethods1(!showListMethods1);
                                                                    }}
                                                                >
                                                                    <div className="radius-15 other-button p-3 w-100 border cursor-pointer d-flex flex-wrap justify-content-center align-items-center">
                                                                        <label className={"cursor-pointer py-3"}>
                                                                            {showListMethods1
                                                                                ? initStrings.others
                                                                                : initStrings.back}
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )}
                                                    </Animated>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Animated>
                </div>
            </div>
        </div>
    );

    function checkToken() {
        loadingAlertFunction();
        if (!params.token) {
            window.location.href = "/";
        } else {
            closeAlertFunction();
        }
    }

    async function startPayment(payment_methode) {
        try {
            let data = {};
            data["token"] = params.token;
            data["payment_methode"] = payment_methode;
            if(isAppOrder2Tracking){
                await postHitTrackingCall({event: 'ads-customer-journey-8-be'});
            }
            let payment = await createOrderCall(data);
            window.location = payment.redirect_url;
        } catch (e) {
            console.log(e);
            validationErrorAlertFunction(initStrings.not_all_data_are_valid);
        }
    }

    async function getAllPaymentMethods() {
        try {
            let response = await getListPaymentMethodsCall(params.token);
            if (response) {
                setListPaymentMethods1(sortPaymentMethods(response.firstOptions));
                setListPaymentMethods2(sortPaymentMethods(response.secondOptions));
                setOrder(response.order);
                setIsAppOrder(response.order.is_app_order);
                setIsAppOrder2Tracking(response.order.is_app_order);
                if(response.order.is_app_order)
                    await postHitTrackingCall({event: 'ads-customer-journey-7-be'});
                else
                    await postHitTrackingCall({event: `order-journey-5-be`});
            }
        } catch (e) {}
    }

    function sortPaymentMethods(list) {
        return list.sort((a, b) =>
            a.name.toString().toLowerCase() > b.name.toString().toLowerCase() ? 1 : -1
        );
    }

    // async function checkAvailabilityValidityData() {
    //     let email = orderKnaekDataRS.studentEmail
    //     try {
    //         let response = await checkAvailabilityValidityDataCall("student_email_address", email)
    //         setSelectedDomain(response.domain)
    //     } catch (e) {
    //
    //     }
    // }
}
