import React from 'react';
import FooterSection from '../../components/FooterSection/Footer';
import KnaekVacancys from "../../components/AppSections/WorkInKnaek/KnaekVacancys";
import VacancysType from "../../components/AppSections/WorkInKnaek/VacancysType";
import ApplyJobCAT from "../../components/AppSections/WorkInKnaek/ApplyJobCAT";

export default function KnaekVacancy() {
    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <KnaekVacancys/>
                <VacancysType/>
                <ApplyJobCAT/>
                <FooterSection/>
            </div>
        </div>
    );
}
