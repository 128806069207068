import axios from "axios";
import { reduxStore } from "../redux/reduxStore";
import { generateSignature, hasAValue } from "../uitls/SharedFunctions";
import { handleTokenRefresh } from "./helper";

export const apiGet = async (
    route,
    data = {},
    headersReceived = (headers) => {}
) => {
    const stateRedux = reduxStore.getState();
    let authDataRS = stateRedux.authDataNEWRS;
    let accessToken = ""
    if (hasAValue(authDataRS)) {
        accessToken = authDataRS.accessToken;
    }

    const { signature, timestamp } = generateSignature(data);

    try {
        const response = await axios.get(route, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                signature: signature,
                timestamp: timestamp,
                culture: "be",
            },
        });

        return response.data;
    } catch (error) {
        return handleTokenRefresh(error, "GET" ,route, data);
    }
};

export const apiDelete = async (route, data = {}) => {
    const stateRedux = reduxStore.getState();
    let authDataRS = stateRedux.authDataNEWRS;
    let accessToken = "";
    if (hasAValue(authDataRS)) {
        accessToken = authDataRS.accessToken;
    }
    const { signature, timestamp } = generateSignature(data);
    try {
        const response = await axios.post(route, data, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                signature: signature,
                timestamp: timestamp,
                culture: "be",
            },
        });

        return response;
    } catch (error) {
        return handleTokenRefresh(error, "DELETE" ,route, data);
    }
};

export const apiPut = async (route, data = {}, options = {}) => {
    const stateRedux = reduxStore.getState();
    let authDataRS = stateRedux.authDataNEWRS;
    let accessToken = "test";
    if (hasAValue(authDataRS)) {
        accessToken = authDataRS.accessToken;
    }
    const { signature, timestamp } = generateSignature(data);
    try {
        const response = await axios.put(route, data, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                signature: signature,
                timestamp: timestamp,
                culture: "be",
            },
        });
        return response;
    } catch (error) {
        return handleTokenRefresh(error, "PUT" ,route, data);
    }
};

export const apiPost = async (route, data = {}, options = {}) => {
    const stateRedux = reduxStore.getState();
    let authDataRS = stateRedux.authDataNEWRS;
    let accessToken = "test";
    if (hasAValue(authDataRS)) {
        accessToken = authDataRS.accessToken;
    }
    const { signature, timestamp } = generateSignature(data);
    const frontendUrl = window.location.href;
    try {
        const response = await axios.post(route, data, {
            headers: {
                Authorization: "Bearer " + accessToken,
                is_app: false,
                "X-Cultuurapp": false,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                'Frontend-Url': frontendUrl,
                signature: signature,
                timestamp: timestamp,
                culture: "be",
            },
        });

        return response;
    } catch (error) {
        return handleTokenRefresh(error, "POST" ,route, data);
    }
};
