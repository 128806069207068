import React, { useEffect, useState } from "react";
import MyRouts from "./routers/routes";
import Header from "./components/HeaderSection/Header";
import { useDispatch, useSelector } from "react-redux";
import { updateLanguageRS } from "./redux/reduxActions/reduxStoreActions";
import i18n from "i18n-js";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import { BrowserRouter as Router } from "react-router-dom";
import LoginComponent from "./components/AppComponents/LoginComponent";
import ReactGA from "react-ga4";
import LoginLinkComponent from "./components/AppComponents/LoginLinkComponent";
import HostnameRedirect from "./routers/HostnameRedirect";
import Loader from "./components/Loader/Loader";


export default function App() {
    const pixelId = process.env.REACT_APP_META_PIXEL_ID; // replace with your Pixel ID
    const tiktokPixelId = process.env.REACT_APP_TIKTOK_PIXEL_ID; // replace with your Pixel ID
    // const googleTagForComDomain = process.env.REACT_APP_GA4_ID_COM;
    // const googleTagForNlDomain = process.env.REACT_APP_GA4_ID_NL;
    // const currentDomain = window.location.hostname;
    const [isLoading, setIsLoading] = useState(true);
    const [timer, setTimer] = useState(() => {
        const storedTimer = parseInt(localStorage.getItem('timer'), 10);
        return !isNaN(storedTimer) ? storedTimer : 1 * 60;
    });


    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const dispatch = useDispatch();
    let appLanguageRS = useSelector((state) => state.appLanguageRS);
    if(params.get('language') && ['be', 'en'].includes(params.get('language')) && params.get('language') != appLanguageRS)
    {
        appLanguageRS = params.get('language');
        dispatch(updateLanguageRS(params.get('language')));
    }
    i18n.locale = appLanguageRS;


    // useEffect(() => {
    //     ReactPixel.init(pixelId);
    //     ReactPixel.pageView();
    //     TiktokPixel.init(tiktokPixelId);
    //     TiktokPixel.pageView();
    //     const googleTagId = currentDomain.includes(".nl")
    //         ? googleTagForNlDomain
    //         : googleTagForComDomain;
    //     ReactGA.initialize(googleTagId);
    // }, []);



    const emailInModalRS = useSelector((state) => state.emailInModalRS);

    useEffect(() => {
        ReactPixel.init(pixelId);
        ReactPixel.pageView();
        // TiktokPixel.init(tiktokPixelId);
        // TiktokPixel.pageView();
        // const googleTagId = currentDomain.includes('.nl') ? googleTagForNlDomain : googleTagForComDomain;
        // ReactGA.initialize(googleTagId);
    }, []);

    useEffect(() => {
        if (!appLanguageRS) {
            if (window.location.toString().toLowerCase().includes("knaek.com")) {
                i18n.locale = "en";
                dispatch(updateLanguageRS("en"))

            } else if (window.location.toString().toLowerCase().includes("knaek.be")) {
                i18n.locale = "be";
                dispatch(updateLanguageRS("be"))
            }
        } else {
            i18n.locale = appLanguageRS;
        }
    }, [appLanguageRS])

    useEffect(() => {
        let interval;
        if (emailInModalRS) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer > 0) {
                        return prevTimer - 1;
                    } else {
                        clearInterval(interval);
                        return 0;
                    }
                });
            }, 1000);
        } else {
            // Clear the timer interval when the modal is closed
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [emailInModalRS]);

    // Update localStorage with timer value whenever it changes
    useEffect(() => {
        localStorage.setItem('timer', timer);
    }, [timer]);

    useEffect(() => {
        const onPageLoad = () => {
            setIsLoading(false)
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <div>
            { isLoading &&
                <Loader />
            }
            <HostnameRedirect />
            <Header switchLanguage={(language) => switchLanguage(language)} imageData={"img/knaek/logo.png"}/>
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up"/>
            </div>
            <Router>
                <MyRouts/>
            </Router>
            <LoginComponent/>
            <LoginLinkComponent timer={timer} setTimer={setTimer} />
        </div>
    );

    function switchLanguage(language) {
        dispatch(updateLanguageRS(language))
    }
}