import React from "react";


export default function KnaekIDUsers() {

    const initStrings = {
        plyImage: "/assets/images/studentkorting/KnaekUsers.png",
    }

    return (
        <section className="section bg-gray align-items-start justify-content-center mt-5 pt-5">
            <div className="container">
                <div className="row">
                    {/*Text under the logo */}
                    <div className="col-12 mb-5">
                        <div className="col-lg-12 col-md-12">
                            <img src={initStrings.plyImage} alt={""}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
