import React, {Component, useState} from 'react';
import AccordionItemKnaekFAQ from "../../AppCustomizedComponent/AccordionItemKnaekFAQ";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";


export default function HowCashbackWorks() {


    const [isExpanded, setIsExpanded] = useState(null);


    const toggleCollapse = (id) => {
        if (isExpanded === id) {
            setIsExpanded(null);
        } else {
            setIsExpanded(id);
        }
    };

    const initStrings = {
        // heading: "Heb je een vraag?",
        // content: "Kijk even of hij in lijstje hieronder staat. Dan heb je het snelst antwoord . No luck? Dan kun je onderaan de pagina direct contact opnemen.",
        // heading1: "Top 3 meest gestelde vragen",
        // heading2: "Veelgestelde vragen",
        "faqData": [
            {
                "id": 1,
                "title": stringsTranslate(appWords.how_do_i_get_my_discount),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.log_in_with_your_knaek_id),
                    },

                ],
            },
            {
                "id": 1,
                "title": stringsTranslate(appWords.how_do_you_know_what_i_have_bought),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.if_you_are_logged_in_and_want_to_make_a_purchase_f),
                    },

                ],
            },
            {
                "id": 1,
                "title": stringsTranslate(appWords.will_i_see_my_discount_immediately_when_i_check_ou),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.because_we_only_receive_money_from_the_webshop_aft),
                    },

                ],
            },
            {
                "id": 1,
                "title": stringsTranslate(appWords.why_does_the_approval_of_my_order_take_longer_than),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords._remains_until_the_webshop_has),
                    },

                ],
            }, {
                "id": 1,
                "title": stringsTranslate(appWords.when_will_i_receive_my_cashback),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.most_webshops_apply_the_statutory_return_period_of),
                    },

                ],
            }, {
                "id": 1,
                "title": stringsTranslate(appWords.how_do_i_get_the_money_from_my_account_to_my_bank_),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.if_your_rebate_is_approved_by_the_webshop__you_can),
                    },

                ],
            },
            {
                "id": 1,
                "title": stringsTranslate(appWords.how_long_does_the_payment_take),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.once_your_cashback_has_been_approved_by_the_websho),
                    },

                ],
            },
            {
                "id": 1,
                "title": stringsTranslate(appWords.why_is_my_transaction_not_yet_approved),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.after_your_purchase__your_transaction_will_be_visi),
                    },

                ],
            }, {
                "id": 1,
                "title": stringsTranslate(appWords.where_is_my_transaction),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.if_your_cashback_is_not_visible__then_the_shop_has),
                    },

                ],
            },
            {
                "id": 1,
                "title": stringsTranslate(appWords.my_cashback_is_too_low__what_now),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.your_cashback_is_calculated_on_the_purchase_amount),
                    },

                ],
            },

            {
                "id": 1,
                "title": stringsTranslate(appWords.is_the_discount_also_valid_with_special_offers_and),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.yes__all_cashback_discounts_are_valid_in_combinati),
                    },

                ],
            },
            {
                "id": 1,
                "title": stringsTranslate(appWords.does_cashback_also_apply_on_purchases_in_shop),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords._you_cannot_receive_cashback_on_in_store_purcha),
                    },

                ],
            },
            {
                "id": 1,
                "title": stringsTranslate(appWords.i_forgot_to_use_cashback__can_i_still_get_it),
                "cashback_content": [
                    {
                        "section": stringsTranslate(appWords.unfortunately__this_is_not_possible),
                    },

                ],
            }
        ],

    }


    return (

        <div className="row mt-5">
            <div className="col-12">
                <div className="accordion">
                    <div className="row justify-content-center radius-10">
                        <div className="col-12 col-md-10 col-lg-12">
                            {initStrings.faqData.map(({title, cashback_content}, idx) => (
                                <AccordionItemKnaekFAQ
                                    key={idx}
                                    title={title}
                                    vacancyContent={cashback_content}
                                    isExpanded={isExpanded}
                                    toggleCollapse={toggleCollapse}
                                    idx={idx}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

