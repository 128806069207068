import React from "react";
import FooterSection from "../../components/FooterSection/Footer";
import KnaekContactSection from "../../components/AppSections/KnaekContact/KnaekContactSection";
import ContactForm from "../../components/AppSections/KnaekContact/ContactForm";
import {Helmet} from "react-helmet";



export default function ContactPage() {
    return (
        <div className={"homepage-4 pt-5"}>
            <div className={"main"}>
                <Helmet>
                    <title>Knaek-contact</title>
                    <meta property="description" content={"Op deze pagina beantwoorden we enkele vragen die vaak gesteld worden. Staat het antwoord op je vraag er niet bij, stuur dan een e-mail naar info@knaek.be, of vul het contactformulier in."} />
                    <meta property="keywords" content={"contact, vragen"} />

                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content="Knaek-contact" />
                    <meta property="og:description" content={"Op deze pagina beantwoorden we enkele vragen die vaak gesteld worden. Staat het antwoord op je vraag er niet bij, stuur dan een e-mail naar info@knaek.be, of vul het contactformulier in."}/>
                    <meta property="og:keywords" content={"contact, vragen"}/>
                    <meta property="og:type" content="website" />

                </Helmet>
                <KnaekContactSection/>
                <ContactForm/>
                <FooterSection/>
            </div>
        </div>
    );
}
