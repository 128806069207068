import React from 'react';
import BannerSectionJobs from "../../components/AppSections/JobsPageSections/BannerSectionJobs";
import JobsPart from "../../components/AppSections/JobsPageSections/JobsPart";

export default function JobsPage() {
    return (
        <div className="pt-5 mt-5">
                <BannerSectionJobs/>
                <JobsPart/>
        </div>
    );
}
