import React from 'react';
import FooterSection from '../../components/FooterSection/Footer';
import PartnersSection from "../../components/AppSections/PartnersOfKnaek/PartnersSection";

export default function PartnersVanKnaek() {


    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <PartnersSection />
                <FooterSection/>
            </div>
        </div>
    );
}
