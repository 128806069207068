import { apiGet, apiPost, apiPut } from "./Api";
import { appUrls } from "../config/Constants";

export async function getAllStoreDiscountsCall() {
    return apiGet(appUrls.backend_api + "valid_actions")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getAllLocationsCall() {
    return apiGet(appUrls.backend_api + "locationsmap")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getAllCitiesCall() {
    return apiGet(appUrls.backend_api + "cities")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getAllVacanciesCall() {
    return apiGet(appUrls.backend_api + "jopp/get_all_vacancies_test")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function loginFunctionCall(data) {
    return apiPost(appUrls.backend_api + "v4/auth/email/login-request", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function passwordForgetFunctionCall(data) {
    return apiPost(appUrls.backend_api + "forgot_password", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getProfileCall() {
    return apiGet(appUrls.backend_api + "v4/users/profile")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getSavingsCall() {
    return apiGet(appUrls.backend_api + "savings")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getFavoritesCall() {
    return apiGet(appUrls.backend_api + "favorites")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function applyVacancyJoppCall(data) {
    return apiPost(appUrls.backend_api + "jopp/apply_for_vacancy_easy", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function alreadyAppliedForVacancyCall(data) {
    return apiGet(
        appUrls.backend_api + "jopp/already_applied_for_vacancy/" + data.id
    )
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function viewVacancyJoppCall(data) {
    return apiPost(appUrls.backend_api + "jopp/view_vacancy", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function postContactMailCall(data) {
    return apiPost(appUrls.backend_api + "website_send_contact", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function checkAvailabilityValidityDataCall(type, value) {
    return apiGet(
        appUrls.backend_api + "check_availability_validity/" + type + "/" + value
    )
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function verifyPhoneNumberSendCall(data) {
    return apiPost(appUrls.backend_api + "verify_phone_number_send", data)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function verifyPhoneNumberCheckNoLoginCall(data) {
    return apiPost(
        appUrls.backend_api + "verify_phone_number_check_no_login",
        data
    )
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getListPaymentMethodsCall(token) {
    return apiGet(appUrls.backend_api + "get_list_payment_methods/" + token)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function updateOrder(data) {
    return apiPut(appUrls.backend_api + "v4/memberships",data)
        .then((result) => {
            return result
        })
        .catch((err) => {
            throw err
        })
}

export async function createOrderCall(data) {
    return apiPost(appUrls.backend_api + "order", data)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function startOrderWebsiteCall(data) {
    return apiPost(appUrls.backend_api + "start_an_order_website", data)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function startOrderIntroCall(data) {
    return apiPost(appUrls.backend_api + "start_an_order_intro", data)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function checkStatusOrderCall(data) {
    return apiPost(appUrls.backend_api + "check_status_order", data)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function createMembershipCancellationCall(data) {
    return apiPost(appUrls.backend_api + "create_membership_cancellation", data)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function confirmMembershipCancellationCall(data) {
    return apiPost(appUrls.backend_api + "confirm_membership_cancellation", data)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function findSubscriptionByIbanBirthdayCall(data) {
    return apiPost(
        appUrls.backend_api + "find_subscription_by_iban_birthday",
        data
    )
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getIntroCall(city, name) {
    return apiGet(appUrls.backend_api + "intro_page/" + city + "/" + name)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function checkOneTimeAuthTokenCall(data) {
    return apiPost(appUrls.backend_api + "check_one_time_token", data)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function updatePasswordWithOneTimeTokenCall(data) {
    return apiPost(
        appUrls.backend_api + "update_password_with_one_time_token",
        data
    )
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function updateStudentEmailWithOneTimeTokenCall(data) {
    return apiPost(
        appUrls.backend_api + "update_student_email_with_one_time_token",
        data
    )
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getAllSnipTechOnlineDiscountsCall(
    page = 1,
    size = 30,
    filters
) {
    let url = `sniptech/stores_locals?page=${page}&size=${size}&consumer=web`;
    if (filters?.length > 0) {
        url += `&${filters}`;
    }
    return apiGet(appUrls.backend_api + url)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getSnipTechStoreByIdCall(id) {
    return apiGet(appUrls.backend_api + `sniptech/stores_locals/${id}`)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getTrackingLinkSTCall(data) {
    return apiPost(appUrls.backend_api + "sniptech/tracking_link", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function createSnipTechTicketCall(data) {
    return apiPost(appUrls.backend_api + "sniptech/tickets", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function fetchMyTransactionSnipTechCall() {
    return apiGet(appUrls.backend_api + "v4/sniptech/transactions")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function fetchSnipTechBalancesCall() {
    return apiGet(appUrls.backend_api + "v4/sniptech/balances")
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function createSnipTechPayoutCall(data) {
    return apiPost(appUrls.backend_api + "sniptech/payout", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function getSnipTechOnlineFavoriteCall(page = 1, size = 100) {
    let url = `sniptech/favorites?page=${page}&size=${size}`;
    return apiGet(appUrls.backend_api + url)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function postUserReviewCall(data) {
    return apiPost(appUrls.backend_api + "user/rating", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function registerFreeDistributionCall(data) {
    return apiPost(appUrls.backend_api + "register-free-distribution", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function loginWithOneTimeTokenCall(data) {
    return apiPost(appUrls.backend_api + "login/one-time-token", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function postRepostProblemCall(data) {
    return apiPost(appUrls.backend_api + "report-problem", data)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function refreshTokenCall(data) {
    return apiPost(appUrls.backend_api + `v4/auth/refresh`, data);
}

export async function postHitTrackingCall(data) {
    return apiPost(appUrls.backend_api + `hit-tracking`, data);
}

export async function checkEmailVerificationCall(token) {
    return apiPost(appUrls.backend_api + "v4/users/verify/" + token)
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
            throw err;
        });
}


export async function getActionDetailsCall(id) {
    return apiGet(appUrls.backend_api + `v4/actions/${id}`)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}

export async function purchaseCouponCall(action_id, numberOfVouchers) {
    return apiPost(appUrls.backend_api + `v4/actions/${action_id}/vouchers/purchase`, {numberOfVouchers:numberOfVouchers})
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}
export async function getPaymentCouponsCall(token) {
    return apiPost(appUrls.backend_api + `v4/actions/vouchers/purchase/${token}/status`)
        .then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}
