import React, {Component, useState} from 'react';
import AccordionItemKnaekFAQ from "../../AppCustomizedComponent/AccordionItemKnaekFAQ";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {appWords} from "../../../config/Constants";





export default function FAQ() {
    const [isExpanded, setIsExpanded] = useState(null);

    const toggleCollapse = (id) => {
        if (isExpanded === id) {
            setIsExpanded(null);
        } else {
            setIsExpanded(id);
        }
    };

    const initStrings = {
        "faqData": [
            {
                "link": 'order-knaek',
                "id": 1,
                "title": stringsTranslate(appWords.how_can_i_order_my_knaek_id),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.you_can_order_your_knaek_id_via_the_website_or_app),
                    },

                ],
            },
            {
                "link": 'knaek-pricing',
                "id": 1,
                "title": stringsTranslate(appWords.what_does_knaek_cost),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.knaek_costs_25_per_college_year_which_is_about),
                    },

                ],
            },
            {
                "link": 'find-knaek-id',
                "id": 1,
                "title": stringsTranslate(appWords.where_can_i_find_my_knaek),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.if_your_membership_has_been_renewed),
                    },

                ],
            },
            {
                "link": 'knaek-id-used',
                "id": 1,
                "title": stringsTranslate(appWords.my_knaek_id_has_already_been_used),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.if_your_knaek_id_has_already_been_used__you_have_p),
                    },

                ],
            },
            {
                "link": 'pas-vs-app',
                "id": 1,
                "title": stringsTranslate(appWords.what_is_the_difference_between_the_knaekpas_and_th),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.the_knaekpas_is_the_physical_version_of_knaek_ids_),
                    },

                ],
            },
            {
                "link": 'cancel-membership',
                "id": 1,
                "title": stringsTranslate(appWords.how_can_i_cancel_my_membership),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.enter_your_e_mail_address_and_you_will_receive_an_),
                    },

                ],
            },
            {
                "link": 'when-cancel',
                "id": 1,
                "title": stringsTranslate(appWords.what_if_i_don),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.if_we_fail_to_debit_the_membership_fee_from_your_a),
                    },

                ],
            },
            {
                "link": 'not-paying',
                "id": 1,
                "title": stringsTranslate(appWords.what_if_I_do_not_pay_for_my_membership),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.if_we_do_not_succeed_in_debiting_membership_fee_from_your),
                    },

                ],
            },
            {
                "id": 1,
                "link": 'remaining-fee',
                "title": stringsTranslate(appWords.how_is_my_remaining_membership_fee_calculated),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.you_have_just_canceled_your_membership_at_knaek_and_an_outstanding_amount_appears_on),
                    },

                ],
            },
            {
                "id": 2,
                "link": 'yearly-fee',
                "title": stringsTranslate(appWords.how_will_i_be_charged_for_my_annual_membership_fee),
                "vacancy_content": [
                    {
                        "section": stringsTranslate(appWords.how_will_i_be_charged_for_my_annual_membership_fee_answer),
                    },

                ],
            },
        ],
    }

    return (
                <div className="row mt-5">
                    <div className="col-12">
                        <div className="accordion">
                            <div className="row justify-content-center radius-10">
                                <div className="col-12 col-md-10 col-lg-12">
                                    {initStrings.faqData.map(({title, vacancy_content, link}, index) => (
                                        <AccordionItemKnaekFAQ
                                            key={index}
                                            title={title}
                                            vacancyContent={vacancy_content}
                                            isExpanded={isExpanded}
                                            toggleCollapse={toggleCollapse}
                                            idx={link}
                                            id={link}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    );
}

