import React from 'react';
import AboutUsIntroSection from "../../components/AppSections/AboutUs/AboutUsIntroSection";
import FooterSection from "../../components/FooterSection/Footer";

export default function KnaekAboutUs() {


    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <AboutUsIntroSection />
                <FooterSection/>
            </div>
        </div>
    );
}
