import React, {useEffect, useState} from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import KnaekPrimaryButton from "../../components/AppComponents/KnaekPrimaryButton";
import {
    checkOneTimeAuthTokenCall,
    updatePasswordWithOneTimeTokenCall,
    updateStudentEmailWithOneTimeTokenCall
} from "../../api/ApiCalls";
import {useParams} from "react-router-dom";
import {successAlertFunction, tokenErrorAlertFunction} from "../../uitls/AlertFunctions";
import {validateEmail, validatePassword} from "../../uitls/DataValidator";


export default function SendStudentEmail() {
    const {token} = useParams();
    const [studentEmail, setStudentEmail] = useState(undefined);
    const [isAuthGood, setIsAuthGood] = useState(false);


    const initStrings = {
        your_student_email: stringsTranslate(appWords.your_student_email),
        provide_us_with_your_student_email_address: stringsTranslate(appWords.provide_us_with_your_student_email_address),
        profile_student_email_label: stringsTranslate(appWords.profile_student_email_label),
        btn_text_confirm: stringsTranslate(appWords.btn_text_confirm),
    }

    useEffect(() => {
        checkOneTimeAuthToken()
    }, [])

    return (
        <section className="section align-items-center justify-content-center mt-5">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12 bg-white">

                        <div className={"text-left mt-5 mb-3 bg-gray p-5 radius-25"}>
                            <h4 className="my-4">{initStrings.provide_us_with_your_student_email_address}</h4>

                            <li className="list-group-item radius-15 contact-form-fields-div border-0 my-4">
                                <label>{initStrings.profile_student_email_label}</label>
                                <input name="student email" type="email"
                                       placeholder={'student@school.nl'}
                                       className="form-control bg-transparent shadow-none"
                                       onChange={(val) => {setStudentEmail(val.target.value)}}
                                />
                            </li>


                            <div className="mt-4 text-right">
                                <KnaekPrimaryButton disabled={!isAuthGood} title={initStrings.btn_text_confirm} onClick={()=>{
                                    updateStudentEmail()
                                }}/>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </section>
    );

    async function checkOneTimeAuthToken() {
        try {
            let response = await checkOneTimeAuthTokenCall({token})
            if (response.valid) setIsAuthGood(true)
            if (!response.valid) {
                tokenErrorAlertFunction(stringsTranslate(appWords.link_expired), "")
                setTimeout(() => {
                    window.location.href = "/"
                }, 4000)
            }

        } catch (e) {
            tokenErrorAlertFunction(stringsTranslate(appWords.link_expired), "")

            setTimeout(() => {
                window.location.href = "/"
            }, 4000)
            console.log(e)
        }
    }


    async function updateStudentEmail() {
        if(token && studentEmail && validateEmail(studentEmail)){
            try {
                let response = await updateStudentEmailWithOneTimeTokenCall({token, student_email: studentEmail })
                successAlertFunction(stringsTranslate(appWords.student_email_updated), "")
                setTimeout(() => {
                    window.location.href = "/"
                }, 4000)
            } catch (e) {
                tokenErrorAlertFunction(stringsTranslate(appWords.email_not_valid), "")
                setTimeout(() => {
                    window.location.href = "/"
                }, 4000)
                console.log(e)
            }
        }else{
            tokenErrorAlertFunction(stringsTranslate(appWords.email_not_valid), "")
        }
    }

}
