import TiktokPixel from 'tiktok-pixel';



export const tiktokTrackPurchase = () =>{
    TiktokPixel.track('Purchase', {
        value: 12.50,
        currency: 'EUR'
    });
}

export const tiktokTrackAddToCart = () =>{
    TiktokPixel.track('AddToCart');
}

export const tiktokTrackInitiateCheckout = () =>{
    TiktokPixel.track('InitiateCheckout');
}

export const tiktokTrackCompleteRegistration = () =>{
    TiktokPixel.track('CompleteRegistration');
}