import React, {useEffect, useState} from 'react';
import BannerSectionStoreDiscounts
    from "../../components/AppSections/StoreDiscountsPageSections/BannerSectionStoreDiscounts";
import StoreDiscountsPart from "../../components/AppSections/StoreDiscountsPageSections/StoreDiscountsPart";

export default function StoreDiscountsPage() {
    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <BannerSectionStoreDiscounts/>
                <StoreDiscountsPart/>
            </div>
        </div>
    );
}
