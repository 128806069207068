import React, {useEffect, useState} from 'react';
import {appLanguage} from "../../config/Constants";

export default function ReviewsHeader({switchLanguage}) {
    const savedLanguage = localStorage.getItem('language') || appLanguage.en;
    const [currentLanguage, setCurrentLanguage] = useState(savedLanguage);

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === appLanguage.be ? appLanguage.en : appLanguage.be;
        setCurrentLanguage(newLanguage);
        localStorage.setItem('language', newLanguage); // Save the new language setting
        switchLanguage(newLanguage);
    };

    const [languageFlag, setLanguageFlag] = useState("");

    useEffect(() => {
        if (currentLanguage === appLanguage.be) {
            setLanguageFlag("https://cms.it.knaek.com/uploads/english_2b352b017b.svg");
        } else if (currentLanguage === appLanguage.en) {
            setLanguageFlag("https://cms.it.knaek.com/uploads/nederlands_9928ec3018.svg");
        }
    }, [currentLanguage]);

    return (
        <header className="navbar navbar-reviews-dark navbar-custom-style">
            <div className="container-fluid ">
                <div className="col-12">
                    <div className="row justify-content-end">
                        <div className="col-lg-11 col-md-9 col-sm-11"></div>
                        <div className="col-lg-1 col-md-3 col-sm-1 d-flex cursor-pointer">
                            <a className="position-relative" onClick={toggleLanguage}>
                                <img
                                    src={languageFlag}
                                    alt="Switcher"
                                    className="position-relative" width="25" height="17"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
