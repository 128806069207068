import React, {useEffect, useRef, useState} from 'react';
import {Animated} from "react-animated-css";
import {useSelector} from "react-redux";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";


export default function CitySelectorHomePage() {
    const citiesRS = useSelector(state => state.citiesRS);
    const [searchText, setSearchText] = useState("")
    const [cities, setCities] = useState(citiesRS)

    const initStrings = {
        dropdownCitySelectTitle: stringsTranslate(appWords.your_student_city),
        dropdownCitySelectSubTitle: stringsTranslate(appWords.select_your_city),
    }

    useEffect(() => {
            let filterData = citiesRS.filter(item => item.name.toString().toLowerCase().includes(searchText.toString().toLowerCase()))
            setCities(filterData)
    }, [searchText])


    return (
        <div className="col-12 text-center mt-5 d-flex justify-content-center ">
            <div className="row w-100 d-flex justify-content-center">
                <div className="dropdown-city-selector shadow radius-50 border-0 bg-white">

                    <section
                        className={"input-city-selector-section d-flex flex-nowrap justify-content-center align-items-center cursor-pointer"}>
                        <div className={"input-city-selector border-0 font-size-18 w-100 px-5 py-4 bg-white text-left"}>
                            <span>{initStrings.dropdownCitySelectSubTitle}</span>
                        </div>
                        <span className={"fa fa-caret-down mr-5 text-primary cursor-pointer"}/>
                    </section>


                    <div className="dropdown-content-city-selector w-100 bg-white text-left ">
                        {cities.map((item, i) => (
                            <p key={i}
                               className="text-option-city-selector p-2 pl-4 border-0 font-size-16 cursor-pointer"
                               onClick={() => goToCityFilter(item.name)}>
                                {item.name}
                            </p>
                        ))}
                    </div>

                </div>
            </div>
        </div>

    );

    function goToCityFilter(city) {
        window.location = "/studentenkorting/" + city

    }
}
